.small-text-block {
    width: 100%;
    max-width: $content-max-width;
    margin: 85px auto 60px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include below(1160px) {
        padding: 0 20px;
    }

    @include below(620px) {
        flex-direction: column;
    }
}

.small-text-block__content {
    width: 50%;
    max-width: 585px;
    padding: 0 30px 0 0;

    @include below(620px) {
        padding: 0;
        width: 100%;
        max-width: none;
        margin: 0 0 40px 0;
    }
}

.small-text-block__title {
    font-size: 36px;
    line-height: 46px;
    color: #000;
    margin: 0 0 20px 0;
    font-weight: $bold;
	font-family: $heading;
}

.small-text-block__text {
    font-size: 20px;
    line-height: 26px;
    color: $textGray;

    p {
        margin: 20px 0;
    }
}

.small-text-block__quote {
    width: 50%;
    max-width: 450px;
    color: $blue;
    font-size: 26px;
    line-height: 32px;
    font-weight: $bold;
    font-family: $heading;
    margin: 0;

    @include below(620px) {
        width: 100%;
        max-width: none;
    }
}

.small-text-block__author {
    font-size: 20px;
    line-height: 26px;
    color: $textGray;
    display: block;
    margin-top: 10px;
    font-weight: $regular;
}
