.related-block {
    width: 100%;
    max-width: $content-max-width;
    margin: 90px auto 70px auto;

    @include below(1160px) {
        padding: 0 20px;
    }
}

.related-block__title {
    font-size: 28px;
    line-height: 38px;
    color: $blue;
    padding-bottom: 45px;
}

.related-block__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .related-block__item:nth-child(n+3) {
        border-top: none;
    }

    @include below(620px) {
        flex-direction: column;

        .related-block__item:nth-child(n+2) {
            border-top: none;
        }
    }
}

.related-block__item {
    font-size: 20px;
    line-height: 24px;
    width: 50%;
    max-width: 500px;
    color: $blue;
    font-weight: $bold;
	font-family: $heading;
    padding: 30px 0;
    text-decoration: none;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    position: relative;
    // display: flex;
    // align-items: center;

    &-inner {
        display: flex;
        flex-direction: column;
        max-width: 90%;
    }

    @include below(1160px) {
        width: 48%;
    }

    @include below(620px) {
        width: 100%;
        max-width: none;
    }

    &:after {
        content: '';
        width: 24px;
        height: 15px;
        right: 0;
        @include vertical-align(absolute);
        background-image: url('../img/left_arrow.svg');
        transition: right .1s cubic-bezier(0,0,.2,1);
    }

    &:hover {
        &:after {
            right: 10px;
        }
    }
}

.related-block__item-subtitle {
    font-size: 20px;
    line-height: 24px;
    color: $grey;
    display: block;
    font-weight: $regular;
}

.related-block__item-title {
    font-size: 20px;
    line-height: 24px;
    color: #000;
    font-weight: $bold;
    font-family: $heading;
    max-width: 90%;
}
