@import "PageAnchorMenu";
@import "LargeHeadingBlock";
@import "ColumnBlocksBlock";
@import "TextContentCarousel";
@import "InfoSection";
@import "ContactSection";
@import "MultipleHeadings";
@import "ColumnsInRows";

.page-template-page-addsourced {
    .SimplePageHeader {
        margin-bottom: 0;
        &__heading {
            @include below($s) {
                font-size: 3rem;
            }

            @include below(800px) {
                font-size: 4rem;
            }
            @include above(800.01px) {
                font-size: 5rem;

                &--has-form {
                    font-size: 4rem;

                }
            }
        }
    }

}
.addsource-page-section {
    //
    overflow-x: hidden;
    .ContentWrap {
        padding-left: 1rem;
        padding-right: 1rem;
        @include above($s) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}