.event-schedule {
    width: 100%;
    max-width: 920px;
    margin: 100px auto;
    padding: 1.5rem;
    background-color: $blue;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12),
            0 2px 4px 0 rgba(0,0,0,0.08);

    @include below(750px) {
        padding: 2rem 1.25rem;
    }
    &__wrap {
        padding: 0 1rem;
    }
    &__title {
        font-size: 2rem;
        line-height: 1.5;
        font-weight: bold;
        margin-bottom: 1.5rem;
        color: $white;

        @include below(620px) {
            font-size: 1.5rem;
        }
        @include below(370px) {
            font-size: 1.25rem;
        }
    }

	&__row {
        color: $white;
        padding-bottom: 1rem;
		&-title {
            font-size: 1.25rem;
        }
		&-bulletlist {
            padding-top: 1rem;
            padding-left: 1.5rem;
			&-item {
                margin-bottom: 0.75rem;
                max-width: 520px;
			}
		}
	}

}
