.event-persons {
    width: 100%;
		max-width: 920px;
		padding: 0;
    margin: 100px auto;

    @include below(930px) {
        padding: 0 1.25rem;
    }

    &__title {
        font-size: 2rem;
        line-height: 1.5;
        font-weight: bold;
        margin-bottom: 1.5rem;
        color: $black;
		@include above(930px) {
			padding-left: 1.5rem;
		}
        @include below(620px) {
            font-size: 1.5rem;
        }
        @include below(370px) {
            font-size: 1.25rem;
        }
    }

	&__person {
        width: 100%;
        padding: 1.5rem;
        margin: auto;
		box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12),
	            0 2px 4px 0 rgba(0,0,0,0.08);
                background-color: $white;
		margin-bottom: 2rem;
	}

	&__person-holder {
		display: flex;
		flex-flow: row;
		flex-wrap:wrap;
	}
	&__person-image {
		width: 100%;
		margin-bottom: 1.5rem;
		@include above($s) {
			flex: 1 1 auto;
			width: 45%;

		}
		&-wrap {
			@include above($s) {
				padding-right: 2rem;
			}
			display: block;

			img {
				width: 100%;
				max-width: 100%;
			}
		}
	}
	&__person-content {
		width: 100%;
		@include above($s) {
			flex: 1 1 auto;
			width: 55%;
		}

		&-name {
			font-size: 1.5rem;
			color: $blue;
			margin-bottom: 0.5rem;
		}
		&-company {
			font-size: 0.875rem;
			color: $black;
			margin-bottom: 0.25rem;
			font-weight: 700;
		}
		&-title {
			font-size: 0.875rem;
			color: $black;
			margin-bottom: 0.5rem;
			font-weight: 700;
		}
		&-description {
			font-size: 1rem;
			line-height: 1.6;
			color: $black;
			margin-bottom: 1rem;
			padding-right: 1rem;
		}
	}
	// &__row {
    //     color: $white;
    //     padding-bottom: 1rem;
	// 	&-title {
    //         font-size: 1.5rem;
    //     }
	// 	&-bulletlist {
    //         padding-top: 1rem;
    //         padding-left: 1.5rem;
	// 		&-item {
    //             margin-bottom: 0.75rem;
    //             max-width: 520px;
	// 		}
	// 	}
	// }

}
