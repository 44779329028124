.whitepaper-form {
    width: 100%;
    max-width: 915px;
    margin: 60px auto;
    padding: 20px;
    background-size: cover;
    background-image: url('../img/placeholder-pattern.jpg');

    &__holder {
        width: 100%;
        height: 100%;
        padding: 75px 8%;
        background: #fff;
        text-align: center;

        @include below(500px) {
            padding: 35px 20px 90px 20px;
        }

        h4 {
            color: $blue;
            font-size: 28px;
            line-height: 38px;

            @include below(500px) {
                font-size: 20px;
                line-height: 30px;
            }
        }

        input[type="email"] {
            width: 100%;
            max-width: 450px;
            margin: 40px auto;
            padding: 20px;
            border: 1px solid $blue;
            background: #fff;
        }

        input[type="submit"] {
            background-color: $blue;
            padding: 13px 33px;
            display: inline-block;
            border-radius: 70px;
            text-decoration: none;
            color: $white;
            font-family: $text;
            font-size: 20px;
            border: none;
            transition: all .2s ease-in-out;

            &:hover {
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
            }
        }
    }
}
