.case-top-header {
    width: 100%;
    height: 544px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $blue;
}

.case-top-header__inner {
    width: 100%;
    max-width: $content-max-width;
    margin: 0 auto;
    @include vertical-align();
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include below(640px) {
        flex-direction: column;
    }
}

.case-top-header__title {
    font-size: 56px;
    color: #fff;
    line-height: 68px;
    font-weight: $bold;
	font-family: $heading;
    @include below(960px) {
        font-size: 36px;
        line-height: 42px;
    }
}

.case-top-header__logo {
    position: relative;
    display: flex;
    align-items: center;
    width: 30%;

    &:before {
        content: '';
        display: inline-block;
        width: 124px;
        position: relative;
        background: #fff;
        height: 8px;
        margin: 14px 20px 0 30px;
    }

    &:after {
        content: '';
        display: inline-block;
        width: 124px;
        position: relative;
        background: #fff;
        height: 8px;
        margin: 14px 30px 0 20px;
    }

    img {
        max-width: 50px;
    }

    @include below(640px) {
        width: 100%;
        justify-content: center;
        order: 3;
        margin-top: 60px;

        &:after {
            width: 100%;
            margin: 14px 0 0 20px;
        }
        &:before {
            width: 100%;
            margin: 14px 20px 0 0;
        }
    }
}
