.career-link-block {
    width: 100%;
    max-width: 1100px;
    margin: 108px auto;

    @include below(1160px) {
        padding: 0 20px;
        margin: 60px auto;
    }

    &__header {
        font-size: $page-heading-size;
        line-height: 68px;
        color: #000;
        font-weight: bold;
        margin-bottom: 30px;

        @include below(500px) {
            font-size: $page-heading-size--small;
            line-height: 42px;
            margin-bottom: 10px;
        }
    }

    &__inner {
        width: 100%;
        display: flex;
        flex-direction: row;

        @include below(770px) {
            flex-direction: column;
        }
    }

    &__text {
        width: 50%;
        max-width: 450px;
        font-size: $body-text-size;
        color: $textGray;
        line-height: 26px;

        p {
            margin: 20px 0;

            a {
                color: $blue;
            }
        }

        @include below(1160px) {
            padding: 0 40px 0 0;
        }
        @include below(770px) {
            width: 100%;
            max-width: none;
        }
    }

    &__preamble {
        font-size: 20px;
        line-height: 26px;
        color: $textGray;
        margin-bottom: 20px;
    }

    &__sidebar-text {
        font-size: 20px;
        line-height: 26px;
        color: $textGray;
        margin-top: 20px;

        a {
            color: $blue;
        }
    }

    &__list-holder {
        width: 50%;
        max-width: 540px;
        margin-left: auto;

        @include below(770px) {
            width: 100%;
            max-width: none;
            margin-left: 0;
            margin-top: 40px;
        }
    }

    &__list {
        width: 100%;

        &-item {
            width: 100%;
            list-style: none;
            font-size: 20px;
            line-height: 26px;
            color: $textGray;
            border-bottom: 1px solid $grey;

            &:first-of-type {
                border-top: 1px solid $grey;
            }
        }

        &-link {
            text-decoration: none;
            display: block;
            position: relative;
            padding-right: 30px;
            color: #000;
            padding: 40px 0;
            font-weight: bold;

            span {
                max-width: 80%;
            }

            &:after {
                content: '';
                width: 24px;
                height: 15px;
                right: 0;
                @include vertical-align(absolute);
                background-image: url('../img/left_arrow.svg');
                transition: right .1s cubic-bezier(0,0,.2,1);
            }

            &:hover {
                &:after {
                    right: 10px;
                }
            }
        }
    }
}
