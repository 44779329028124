.instagram-feed {
    width: 100%;
    max-width: 1100px;
    margin: 50px auto;

    @include below(1160px) {
        padding: 0 20px;
    }

    &__profile-link {
        font-size: 36px;
        line-height: 42px;
        color: $blue;
        text-decoration: none;
        font-weight: bold;
        margin-bottom: 20px;
        display: block;

        @include below(500px) {
            font-size: 28px;
            line-height: 32px;
        }
    }

    &__list {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__holder {
        width: 25%;
        padding: 5px;
        display: block;

        @include below(500px) {
            width: 100%;
        }

        img {
            max-width: 100%;
            display: block;
        }
    }
}
