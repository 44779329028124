.bottom-menu {
    width: 100%;
    max-width: $content-max-width;
    margin: 70px auto 0 auto;
    padding: 20px 10px;
    background: #fff;
    box-shadow: 0 10px 25px 0 rgba(0,0,0,0.08);
    display: flex;
    flex-direction: row;

    @include below(760px) {
        display: none;
    }
}

.bottom-menu__column {
    width: calc(100% / 3);
}

.bottom-menu__current-link {
    text-align: center;
    color: $blue;
    font-size: 18px;
    line-height: 22px;
    font-weight: $regular;
    display: block;
    text-decoration: none;
}

.bottom-menu__next-link {
    text-align: center;
    color: $grey;
    font-size: 18px;
    line-height: 22px;
    font-weight: $regular;
    display: block;
    text-align: right;
    text-decoration: none;
    transition: color .1s ease;

    &:hover {
        color: $blue;

        &:after {
            background-image: url('../img/left_arrow.svg');
        }
    }

    &:after {
        content: '';
        display: inline-block;
        width: 18px;
        height: 11px;
        margin-left: 13px;
        background-size: cover;
        background-image: url('../img/grayrightarrow.svg');
    }
}

.bottom-menu__archive-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;

    &:hover {
        .bottom-menu__hamburger-line {
            &:before {
                top: -4px;
            }
            &:after {
                top: 4px;
            }
        }
    }
}

.bottom-menu__hamburger {
    width: 23px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 13px;
}

.bottom-menu__hamburger-text {
    color: $blue;
    font-size: 18px;
    line-height: 22px;
}

.bottom-menu__hamburger-line {
    display: block;
    position: relative;
    width: 100%;
    height: 1.5px;
    background: $blue;

    &:before, &:after {
        content: '';
        width: 100%;
        position: absolute;
        height: 1.5px;
        background: $blue;
        transition: top .1s ease-in-out;
    }

    &:before {
        top: -7px;
    }
    &:after {
        top: 7px;
    }
}
