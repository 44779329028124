.LargeHeadingBlock {
        width: 100%;
        max-width: $m;
        margin: 60px auto 30px auto;
        text-align: center;

        @include below(1160px) {
            padding: 3rem 20px;
        }

        &-heading {
            font-size: $page-heading-size;
            line-height: 1.1;
            color: $blue;
            font-weight: $bold;
            font-family: $heading;

            @include below($m) {
                font-size: $page-heading-size--small;
                line-height: 1.1;
            }
        }
}