.blue-ingress-block {
    width: 100%;
    max-width: $content-max-width;
    margin: 56px auto 46px auto;

    @include below(1160px) {
        padding: 0 20px;
    }

    h4 {
        font-size: $body-ingress-size;
        line-height: 32px;
        color: $blue;
        max-width: 660px;
    }
}
