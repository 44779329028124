.Map-block {
	&__heading {
		max-width: $content-max-width;
		width: 100%;
		margin: auto;
		padding: 2rem 0;  /* 120/16 */
		font-size: $page-heading-size;
        line-height: 1.3;
        color: #000;
        font-weight: $bold;
		font-family: $heading;
        @include below($m) {
            font-size: $page-heading-size--small;
            line-height: 42px;
        }
		@include below($content-max-width) {
			margin: 1rem 0;
			padding: 0 $content-padding;
		}
	}

	&__map {
		min-height: 360px;
		display: block;
		width: 100%;
		background-color: $blue;
		@include background-svg($svg-pattern);
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;

	}
}
