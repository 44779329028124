.title-text-block {
    width: 100%;
    max-width: 1160px;
    margin: 120px auto 125px auto;
    display: flex;
    flex-direction: row;
    font-family: $text;

    @include below(1170px) {
        padding: 0 20px;
    }

    @include below(700px) {
        flex-direction: column;
        margin: 60px auto;
    }
}

.title-text-block__title {
    width: 60%;
    font-size: 72px;
    line-height: 86px;
    padding-right: 20px;
    font-family: $heading;
    color: $blue;
    font-weight: $bold;

    @include below(700px) {
        width: 100%;
        margin-bottom: 30px;
        font-size: 52px;
        line-height: 66px;
    }
}

.title-text-block__text {
    width: 40%;
    font-size: 20px;
    font-weight: $regular;
    line-height: 26px;
    color: $blue;

    @include below(700px) {
        width: 100%;
    }
}
