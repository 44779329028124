.Video-block {
	max-width: $content-max-width;
	width: 100%;
	margin: auto;
	padding: 2rem 0;  /* 120/16 */

	@include below($content-max-width) {
		margin: 1rem 0;
		padding: 0 $content-padding;
	}

	&__holder {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 100%;
		max-width: 100%;
		width: 100%;
		background-color: $blue;
		@include background-svg($svg-pattern);
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	&__video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity .3s ease;
		z-index: 1;
	}
}
