.ColumnsInRows {
    //
    max-width: $content-max-width;
    margin-left: auto;
    margin-right: auto;
    padding: 4rem 1rem;
    text-align: center;

    &-small-heading {
        color: $blue;
        font-size: $text-base;
        padding-bottom: 5rem;
        margin: auto;
    }
    &-grid {
        justify-content: center;
    }
    &-column {
        text-align: center;
        position: relative;
        flex: auto !important;
        flex-shrink: 0 !important;
        flex-grow: 0 !important;
        padding: 0 2rem 2rem 2rem;

        @include above($m) {
            padding: 0 3rem 3rem 3rem;

        }
        &-link {
            display: block;
            text-decoration: none;
        }
        &-image {
                width: 100%;
                max-width: 100%;
                display: block;
                padding-bottom: 2rem;
            img{
                width: 100%;
                max-width: 100%;
                display: block;
            }
        }
        &-heading {
            color: $blue;
            font-size: $text-base;
            padding-bottom: 1rem;
        }
        &-text {
            font-size: $text-sm;
            color: $black;
        }
    }
}