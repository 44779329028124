// Grid with lost (postcss)
@lost gutter 2rem;
@lost cycle auto;
@lost rounder 99.69999;

.row {
	lost-utility: clearfix;
	width: 100%;
	margin: auto;
	z-index: 1;
	max-width: $content-max-width;
	display: block;

	@include below($content-max-width) {
		max-width: 100%;
		padding-left: 1rem;
		padding-right: 1rem;
	}

 	.row {
		padding: 0;
	}
}

.flex-row {

	lost-flex-container: row;
	width: 100%;
	margin: auto;
	z-index: 1;
	max-width: $content-max-width;

	@include below($content-max-width) {
		max-width: 100%;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.flex-row {
		padding: 0;
	}
}

.grid {
	lost-utility: clearfix;
}
.clear {
	lost-utility: clearfix;
}

.justify {
	&--center {
		justify-content: center;
	}
	&--space-between {
		justify-content: space-between;
	}

	&--end {
		justify-content: flex-end;
	}

	&--start {
		justify-content: flex-start;
	}
}

.align {
	&--vertical {
			lost-align: center flex;
			align-items: center;
	}

	&-columns--vertical {
		@include above($s) {
			lost-align: center flex;
			align-items: center;
		}
	}
}

.col-center {
	float:none;

	&--large {
		lost-center: $content-max-width;
		@include below($content-max-width) {
			max-width: 90%;
		}
	}
	&--medium {
		lost-center: 800px;
		@include below($m) {
			max-width: 90%;
		}
	}
	&--small {
		lost-center: 420px;
		@include below(420px) {
			max-width: 90%;
		}
	}
}

.col--grow {
	flex-grow: 1 !important; // sorry, but immutable
}

/** Columns **/
$col-min : 1;
$col-max : 12;
.row {
@for $i from $col-min through $col-max {

	$extra: if($i == $col-max, 0 0, null);

	.flex-col-#{$i} {
		lost-column: #{$i}/12 $extra flex;
	}
	.col-#{$i} {
		lost-column: #{$i}/12 $extra;
	}

	@include above($m) {
		.col-#{$i}-move--right {
			lost-move: #{$i}/12 1rem;
		}
		.col-#{$i}-move--left {
			lost-move: -#{$i}/12 1rem;
		}
		.col-#{$i}-offset--right {
			lost-offset: #{$i}/12 1rem;
		}
		.col-#{$i}-offset--left {
			lost-offset: -#{$i}/12 1rem;
		}
	}
}

@include below($m) {
	@for $i from $col-min through $col-max {
		$extra: if($i == $col-max, 0 0, null);

		.flex-col-#{$i} {
			lost-column: 12/12 $extra flex;
		}
		.col-#{$i} {
			lost-column: 12/12 $extra;
		}
	}
}

@include between($s, $m) {
	@for $i from $col-min through $col-max {

	$extra: if($i == $col-max, 0 0, null);

		.m-col-#{$i} {
				lost-column: #{$i}/12 $extra;
			@if $i == 12 {
				  float: none;
				  width: 100%;
				  max-width: 100%;
					margin: 0;
			}


		}

		.m-flex-col-#{$i} {
			lost-column: #{$i}/12 $extra flex;
		}
		.m-col-#{$i}-move--right {
			lost-move: #{$i}/12 1rem;
		}
		.m-col-#{$i}-move--left {
			lost-move: -#{$i}/12 1rem;
		}
		.m-col-#{$i}-offset--right {
			lost-offset: #{$i}/12 1rem;
		}
		.m-col-#{$i}-offset--left {
			lost-offset: -#{$i}/12 1rem;
		}

	}
}

@include below($s) {
	@for $i from $col-min through $col-max {
	$extra: if($i == $col-max, 0 0, null);

		.s-col-#{$i} {
				lost-column: #{$i}/12 $extra;
				@if $i == 12 {
					  float: none;
					  width: 100%;
					 	max-width: 100%;
						margin: 0;
				}
		}

		.s-flex-col-#{$i} {
			lost-column: #{$i}/12 $extra flex;
		}

		.s-col-#{$i}-move--right {
			lost-move: #{$i}/12;
		}
		.s-col-#{$i}-move--left {
			lost-move: -#{$i}/12;
		}
		.s-col-#{$i}-offset--right {
			lost-offset: #{$i}/12;
		}
		.s-col-#{$i}-offset--left {
			lost-offset: -#{$i}/12
		}
	}
}
}



.column-grid {
	@for $i from 1 through 12 {
		$extra: if($i == $col-max, 0 0, null);

		@include above($m) {
			.grid-col-#{$i} {
				lost-column: #{$i}/12 (12/#{$i}) 1rem flex;
			}
		}

		@include between($s, $m) {
			.grid-m-col-#{$i} {
				lost-column: #{$i}/12 (12/#{$i}) 1rem flex;
			}
		}

		@include below($s) {
					.grid-s-col-#{$i} {
						lost-column: #{$i}/12 (12/#{$i}) 1rem flex;
					}
		}
	}
}

@include between($s, $m) {
	.hide-on-m {
		display: none !important; // Sorry, but immutable
	}
}
@include below($m) {
	.hide-below-m {
		display: none !important; // Sorry, but immutable
	}
}
@include above($m) {
	.hide-above-m {
		display: none !important; // Sorry, but immutable
	}
}
@include above($s) {
	.hide-above-s {
		display: none !important; // Sorry, but immutable
	}
}
@include below($s) {
	.hide-below-s {
		display: none !important; // Sorry, but immutable
	}
	.col-center--large,
	.col-center--medium,
	.col-center--small {
		lost-center: 100%;
	}
}

@include below($xs) {
	.hide-below-xs {
		display: none !important; // Sorry, but immutable
	}
}

//
//
.flex-grid {
	display: flex;
	&--row {
		flex-flow: row wrap;
	}

	&--column {
		flex-flow: column wrap;
	}
	/** Columns **/
	$col-min : 1;
	$col-max : 12;
	@for $i from $col-min through $col-max {

		$extra: if($i == $col-max, 0 0, null);
		$base-percentage: ($i / 12) * 99.7999;
        $percentageVal: decimal-round($base-percentage, 8);
        $percentage: #{$percentageVal}#{"%"};

        .col-#{$i} {
            flex-basis: $percentage;
            width: $percentage;
            max-width: $percentage;
            flex: 1 1 auto;
        }

		@include between($s, $m) {
			.m-col-#{$i} {
				flex-basis: $percentage;
				width: $percentage;
				max-width: $percentage;
				flex: 1 1 auto;

				@if $i == 12 {
						width: 100%;
						flex-basis: 100%;
						max-width: 100%;
						margin: 0;
				}
            }
		}

		@include below($s) {
			.s-col-#{$i} {
				flex-basis: $percentage;
				width: $percentage;
				max-width: $percentage;
				flex: 1 1 auto;
				@if $i == 12 {
						width: 100%;
						flex-basis: 100%;
						max-width: 100%;
						margin: 0;
				}
            }
		}
	}
}
