.event-text {
    width: 100%;
	max-width: 920px;
	padding: 0;
    margin: 100px auto;

    @include below(930px) {
        padding: 0 1.25rem;
    }

    &__title {
        font-size: 3.5rem;
        line-height: 1.5;
        font-weight: bold;
        margin-bottom: 1.5rem;
        color: $black;
		@include above(930px) {
			padding-left: 1.5rem;
		}
        @include below(620px) {
            font-size: 2rem;
        }
        @include below(370px) {
            font-size: 1.5rem;
        }
    }

	&__content {
        width: 100%;
        font-size: 1.125rem;
        line-height: 1.3;
		color: #575757;
		@include above(930px) {
			padding-left: 1.5rem;
		}
		max-width: 720px;

        p, span, a {
            font-size: inherit;
            line-height: inherit;
			color: inherit;
        }
		p {
			margin-bottom: 1.5rem;
		}
        a {
            text-decoration: underline;
        }
	}

}
