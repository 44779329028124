.TextContentCarousel {
    background-color: $darkBlue;
    color: $white;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .flickity-viewport {
        width: 100%;
        overflow: visible;
    }
    @include above($m) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    &-carousel {
        font-size: $body-text-size;
        line-height: 1;
         max-width: $content-max-width;
        margin-left: auto;
        margin-right: auto;
    }
    &-header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding-right: 2rem;
        padding-bottom: 2rem;
        max-width: $content-max-width;
        margin-left: auto;
        margin-right: auto;
    }
    &-heading h4 {
        font-size: $body-text-size;
        line-height: 1;
        font-weight: 400;
        font-family: $text;
    }
    &-pagination {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }
    &-arrow {
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;

        cursor: pointer;
        transition: opacity 0.2s linear;
        background: none;
        border: none;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:disabled {
            opacity: 0.3;
            pointer-events: none;
        }
        &:focus,
        &:active {
            border: 0;
            outline: 0;
        }
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
    &-arrow-img {

        width: 24px;
        height: 16px;
        display: block;

        .TextContentCarousel-prev & {
            transform: rotate(180deg);
            transform-origin: center;
        }
    }

    &-carousel {
        overflow: visible;
        display: flex;
        flex-flow: row nowrap;
    }
    &-column {
        width: 50%;
        // max-width: 414px;
        border-top: solid 0.5rem $white;
        padding: 2rem 2rem 2rem 0rem;

        @include below($s) {
            max-width: 90%;
        }
        @include above($m) {
            width: 33.33%;
            max-width: 33.33%;
        }
        &-heading {
            font-family: $heading;
            font-weight: $bold;
            padding-bottom: 2rem;
        }
        &-text {
            font-size: 1rem;
            line-height: 1.5;
        }
    }
}