.large-heading-block {
    width: 100%;
    max-width: $content-max-width;
    margin: 60px auto 30px auto;

    @include below(1160px) {
        padding: 0 20px;
    }

    h2 {
        font-size: $page-heading-size;
        line-height: 68px;
        color: #000;
        font-weight: $bold;
		font-family: $heading;

        @include below(620px) {
            font-size: 30px;
            line-height: 38px;
        }
    }
}
