
$xs: 414px;
$s: 640px;
$m: 800px;
$l: 1050px;
$xl: 1410px;

$lab-grotesque: "Lab Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$lab-grotesque-bold: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$text: $lab-grotesque;
$heading: $lab-grotesque-bold;

// Weights
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

$page-heading-size: 3.5rem; /* 56/16 */
$page-heading-size--small: 2.25rem; /* 36/16 */
$page-content-heading-size: 2.25rem; /* 36/16 */
$body-text-size: 1.25rem; /* 20/16 */

$body-ingress-size: 1.75rem; /* 28/16 */
$body-quote-size: 1.625rem; /* 28/16 */ /* 26/16 */

$text-sm: 1rem;
$text-base: $body-text-size;
$text-md: $body-ingress-size;
$text-lg: $page-content-heading-size;
$text-xl: $page-heading-size;
// Default content settings.
$content-padding: 1.25rem;
$content-max-width: 68.75rem; /* 1100/16 */

// sass-lint:disable variable-name-format
$black: #1E1E1E;
$grey: #BBBBBB;
$gray: #BBBBBB;
$beige: #E2B881;
$orange: #FFA42C;
$green: #19E069;
$blue: #2385FC;
$lightBlue: #E9F2FE;
$light-blue: $lightBlue;
$darkBlue: #2346B5;
$dark-blue: $darkBlue;
$white: #fff;
$linkColor: #4A90E2;
$link-color: $linkColor;
$textGray: #575757;
$text-gray: $textGray;
$svg-pattern: "<svg version='1.1' x='0px' y='0px' viewBox='0 0 1916.3 1114.5' enable-background='new 0 0 1916.3 1114.5'>
<path fill='#2D78FF' d='M0.4-3.1v1117.7L844.4-2.2C844.4-2.2,0.5-3.3,0.4-3.1z'/>
<polygon fill='#2A83FF' points='844.4,-2.2 0.4,1114.6 1181.6,1114.6 '/>
<polygon fill='#2D94FF' points='844.4,-2.2 1916.2,-2.2 1081.9,784.3 '/>
<polygon fill='#26A3FF' points='1181.6,1114.6 1081.8,784.4 1916.2,-2.2 1916.2,1114.7 '/>
</svg>";
@import "shared/breaker";
@import "shared/mixins";
