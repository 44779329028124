.text-columns {
    width: 100%;
    max-width: 1060px;
    margin: 110px auto 140px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include below(1100px) {
        padding: 0 20px;
    }

    @include below(760px) {
        flex-direction: column;
        margin: 0 auto;
    }
}

.text-columns__column {
    width: 50%;
    max-width: 505px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .button {
        margin-top: auto;
    }

    @include below(760px) {
        width: 100%;
        max-width: none;
        margin-bottom: 60px;
    }
}

.text-columns__title {
    font-size: 56px;
    line-height: 68px;
    color: #000;
	font-family: $heading;
    font-weight: $bold;
    margin-bottom: 30px;

    @include below(500px) {
        font-size: 36px;
        line-height: 43px;
    }
}

.text-columns__text {
    font-size: 20px;
    line-height: 26px;
    font-weight: $regular;
    margin-bottom: 35px;
    max-width: 470px;
    padding-right: 20px;

    @include below(760px) {
        max-width: none;
    }
}

.text-columns__button {
    display: inline-block;
    padding: 20px 30px;
    background: $blue;
    color: #fff;
    font-size: 20px;
    box-shadow: 0 10px 25px 0 rgba(0,0,0,0.15);
    border-radius: 100px;
    text-align: center;
    line-height: 14px;
    margin-top: auto;
    text-decoration: none;
}
