// Line up.
@mixin vertical-align($position: relative){
	position: $position;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin vertical-align($position: relative){
	  position: $position;
	  top: 50%;
	  transform: translateY(-50%);
}

@mixin horizontal-align($position: relative){
	  position: $position;
	  left: 50%;
	  transform: translateX(-50%);
}

@mixin align-both($position: relative){
	 position: $position;
	 left: 50%;
	 top:50%;
	 transform: translate(-50%, -50%);
}

@mixin no-align($position:relative){
	position:initial !important;
	left: initial !important;
	top: initial !important;
	transform:none !important;
}

// Lets dance with the gradients
@mixin gradient($top, $bottom, $base, $min:0%,$max:100%){
    background: $base;
    background-image: -moz-linear-gradient(bottom,  $bottom $min, top $max);
    background-image: -o-linear-gradient(bottom, $bottom $min, top $max);
    background-image: -webkit-linear-gradient(bottom, $bottom $min,  top $max);
    background-image: linear-gradient(bottom, $bottom $min, $top $max);
}
@mixin gradient-horizontal($left, $right, $base, $min:0%,$max:100%){
    background: $base; /* Old browsers */
    background: -moz-linear-gradient(left, $left $min, $right $max); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop($min,$left), color-stop($max,$right)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, $left $min,$right $max); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, $left $min,$right $max); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, $left $min,$right $max); /* IE10+ */
    background: linear-gradient(to right, $left $min,$right $max); /* W3C */
}


@function svg-url($svg){
    //
    //  Add missing namespace
    //
    @if not str-index($svg,xmlns) {
        $svg: str-replace($svg, "<svg", "<svg xmlns='http://www.w3.org/2000/svg'");
    }
    //
    //  Chunk up string in order to avoid
    //  "stack level too deep" error
    //
    $encoded:"";
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg)/$slice);
    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        //
        //   Encode
        //
        $chunk: str-replace($chunk,"'", "\'");
        $chunk: str-replace($chunk,"%", "%25");
        $chunk: str-replace($chunk,"&", "%26");
        $chunk: str-replace($chunk,"#", "%23");
        $chunk: str-replace($chunk,"{", "%7B");
        $chunk: str-replace($chunk,"}", "%7D");
        $chunk: str-replace($chunk,"<", "%3C");
        $chunk: str-replace($chunk,">", "%3E");

        //
        //    The maybe list
        //
        //    Keep size and compile time down
        //    ... only add on documented fail
        //
        //  $chunk: str-replace($chunk, '|', '%7C');
        //  $chunk: str-replace($chunk, '[', '%5B');
        //  $chunk: str-replace($chunk, ']', '%5D');
        //  $chunk: str-replace($chunk, '^', '%5E');
        //  $chunk: str-replace($chunk, '`', '%60');
        //  $chunk: str-replace($chunk, ';', '%3B');
        //  $chunk: str-replace($chunk, '?', '%3F');
        //  $chunk: str-replace($chunk, ':', '%3A');
        //  $chunk: str-replace($chunk, '@', '%40');
        //  $chunk: str-replace($chunk, '=', '%3D');

        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }
    @return url("data:image/svg+xml,#{$encoded}");
}

//  Background svg mixin
@mixin background-svg($svg){
    background-image: svg-url($svg);
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);
    @return if($index,
        str-slice($string, 1, $index - 1) + $replace +
        str-replace(str-slice($string, $index +
        str-length($search)), $search, $replace),
        $string);
}

@function decimal-round ($number, $digits: 0, $mode: round) {
    $n: 1;
    // $number must be a number
    @if type-of($number) != number {
        @warn "#{ $number } is not a number.";
        @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) != number {
        @warn "#{ $digits } is not a number.";
        @return $number;
    } @else if not unitless($digits) {
        @warn "#{ $digits } has a unit.";
        @return $number;
    }
    @for $i from 1 through $digits {
        $n: $n * 10;
    }
    @if $mode == round {
        @return round($number * $n) / $n;
    } @else if $mode == ceil {
        @return ceil($number * $n) / $n;
    } @else if $mode == floor {
        @return floor($number * $n) / $n;
    } @else {
        @warn "#{ $mode } is undefined keyword.";
        @return $number;
    }
}

// Ceil a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @return {Number} A ceiled number
// @example
//     decimal-ceil(0.333)    => 1
//     decimal-ceil(0.333, 1) => 0.4
//     decimal-ceil(0.333, 2) => 0.34
//     decimal-ceil(0.666)    => 1
//     decimal-ceil(0.666, 1) => 0.7
//     decimal-ceil(0.666, 2) => 0.67
//
@function decimal-ceil ($number, $digits: 0) {
    @return decimal-round($number, $digits, ceil);
}

// Floor a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @return {Number} A floored number
// @example
//     decimal-floor(0.333)    => 0
//     decimal-floor(0.333, 1) => 0.3
//     decimal-floor(0.333, 2) => 0.33
//     decimal-floor(0.666)    => 0
//     decimal-floor(0.666, 1) => 0.6
//     decimal-floor(0.666, 2) => 0.66
//
@function decimal-floor ($number, $digits: 0) {
    @return decimal-round($number, $digits, floor);
}
