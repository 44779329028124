.article-header {
    width: 100%;
    max-width: 1100px;
    margin: 120px auto 40px auto;

    @include below(1160px) {
        padding: 0 20px;
    }

    @include below(500px) {
        margin: 60px auto;
    }

    &__title {
        font-size: 36px;
        line-height: 43px;
        letter-spacing: 0.63px;
        color: #2A292E;
        font-weight: $bold;
		font-family: $heading;
        margin-bottom: 20px;
    }

    &__preamble {
        font-size: 24px;
        line-height: 26px;
        letter-spacing: 0.41px;
        color: #2A292E;
        margin-bottom: 20px;
    }

    &__author {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.25px;
        color: $textGray;
        font-weight: $bold;
        font-family: $heading;
        display: block;
        margin-bottom: 40px;
    }

    &__image {
        margin-bottom: 40px;

        img {
            max-width: 100%;
        }
    }
}
