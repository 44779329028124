.case-references-page-content {
	.layout-case_link_block:first-of-type {
        margin-top: -4.375rem; /* -70/16 */
    }
}
.case-column {
    width: 100%;
    max-width: $content-max-width;
    margin: 0 auto 50px auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include below($content-max-width) {
        padding: 0 20px;
    }

    @include below($s) {
        flex-direction: column;
    }
    margin-bottom: 3.125rem; /* 50/16 */
}

.case-column__image-column  {
	.case-column--image-right & {
        order: 2;
        margin-left: auto;
        margin-right: 0;

        @include below($s) {
            order: 1;
            margin-left: 0;
        }
    }
}
.case-column__image {
	max-width: 100%;
    width: 100%;
    display: block;
	img {
        width: 100%;
		max-width: inherit;
        display: block;
	}

}
.case-column__content-column {
    padding-left: 2rem;
	.case-column--image-right & {
        order: 1;
        margin-left: 0;
        padding-left: 0;
        padding-right: 2rem;
        @include below($s) {
            order: 2;
        }

        .case-column__content {
            margin-left: 0;
        	margin-right: auto;
        }
    }

    @include below($s) {
        padding-left: 0;
        padding-right: 0;
    }
}

.case-column--image-right {

}

.case-column__content {
    padding: 4rem 0;
	max-width: 460px;
	margin-left: auto;

    @include below($s) {
        padding: 2rem 0 1rem;
    }
}

.case-column__subtitle {
    font-size: 20px;
    line-height: 24px;
    color: $grey;
    display: block;
    margin: 0 0 30px 0;
}

.case-column__title {
    font-size: 36px;
    line-height: 43px;
    color: #000;
    display: block;
    font-weight: $bold;
	font-family: $heading;
    margin: 0 0 30px 0;
}

.case-column__text {
    font-size: 20px;
    line-height: 24px;
    color: $textGray;
    display: block;
    margin: 0 0 30px 0;
}

.case-column__link {
    display: block;
    text-decoration: none;
    color: $blue;
    font-size: 20px;
    position: relative;

    &:after {
        content: '';
        width: 17px;
        height: 11px;
        margin-left: 8px;
        @include vertical-align(absolute);
        background-image: url('../img/left_arrow.svg');
        transition: margin-left .1s cubic-bezier(0,0,.2,1);
        background-size: cover;
    }

    &:hover {
        &:after {
            margin-left: 12px;
        }
    }
}
