.image-center-text-button {
    width: 100%;
    min-height: 540px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
	color: $white;
    margin-top: 100px;
	background-color: $blue;
    height: 1px;

	&--no-image {
		@include background-svg($svg-pattern);
	}
    @include below(500px) {
        min-height: 444px;
    }
}

.image-center-text-button__inner {
    margin: 0 auto;
    text-align: center;
}

.image-center-text-button__title {
    font-size: $page-heading-size;
    line-height: 68px;
    color: #fff;
    margin-bottom: 10px;
    font-weight: $bold;
	font-family: $heading;

    @include below(500px) {
        font-size: $page-heading-size--small;
        line-height: 42px;
    }
}

.image-center-text-button__text {
    font-size: $body-text-size;
    line-height: 26px;
    color: #fff;
    margin-bottom: 30px;
}
