.large-block-quote {
    width: 100%;
    max-width: 1360px;
    background: rgba($blue, 0.1);
    margin: 72px auto;
    padding: 178px 20px;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;

    @include below(760px) {
        padding: 48px 20px;
    }

    blockquote {
        font-size: $page-content-heading-size;
        line-height: 42px;
        color: $blue;
        font-weight: $bold;
		font-family: $heading;
        max-width: 920px;
        width: 100%;

        @include below(620px) {
            font-size: $body-quote-size;
            line-height: 32px;
        }
    }

    p {
        font-size: $body-text-size;
        color: $textGray;
        margin-top: 20px;
    }
}
