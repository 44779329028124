.site-footer {
    background-color: $blue;
    overflow: hidden;

    &__top-container {
        max-width: 1100px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 65px 20px 120px 20px;

        @include below(850px) {
            flex-direction: column;
            justify-content: center;
        }

        .menu-footer-menu-container {
            max-width: 700px;
            width: 100%;

            @include below(850px) {
                margin: 0 auto;
                text-align: center;
            }

            ul {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                list-style: none;

                @include below(620px) {
                    flex-direction: column;
                }

                li {
                    transition: all 0.2s ease-in-out;

                    @include below(620px) {
                        margin-bottom: 30px;
                    }

                    &.current-menu-item {
                        a:after {
                            transform: translateY(5px);
                            opacity: 1;
                        }
                    }
                }

                li a {
                    text-decoration: none;
                    font-family: $text;
                    color: $white;
                    font-size: 18px;
                    line-height: 22px;
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        background-color: white;
                        left: 0;
                        bottom: 0;
                        transform: translateY(12px);
                        opacity: 0;
                        transition: all 0.2s ease-in-out;
                    }

                    &:hover:after {
                        transform: translateY(5px);
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__social-container {
        @include below(850px) {
            width: 100%;
            text-align: center;
            margin-top: 60px;
        }
    }

    &__social-link {
        display: inline-block;
        background: $white;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        vertical-align: middle;
        position: relative;
        margin-bottom: 15px;
        margin-right: 8px;
        cursor: pointer;
        transition: transform .1s ease-in-out;

        &:hover {
            transform: translateY(-3px);
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__logo {
        width: 48px;
        height: auto;
    }

    &__bottom-container {
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        padding: 0 20px 40px 20px;
    }

    &__logo-container {
        display: flex;
        align-items: center;
        width: 50px;
        position: relative;

        &:before {
            content: '';
            display: block;
            width: 2500px;
            position: absolute;
            background: #fff;
            height: 8px;
            right: 100%;
            margin-top: 7px;
            transform: skewX(-23deg);
        }

        &:after {
            content: '';
            display: block;
            width: 2500px;
            position: absolute;
            background: #fff;
            left: 100%;
            margin-left: -3px;
            height: 8px;
            margin-top: 7px;
            transform: skewX(23deg);
        }
    }
}
