.text-heading-highlight {
    width: 100%;
    max-width: 1100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 50px auto;

    @include below(1160px) {
        padding: 0 20px;
    }

    @include below(770px) {
        flex-direction: column;
    }

    &__text {
        width: 50%;
        max-width: 635px;
        padding: 0 20px 0 0;

        @include below(770px) {
            width: 100%;
            max-width: none;
        }

        h3 {
            font-size: 36px;
            line-height: 43px;
            font-weight: $bold;
			font-family: $heading;
            margin-bottom: 30px;

            @include below(500px) {
                font-size: 22px;
                line-height: 28px;
            }
        }

        p {
            font-size: 20px;
            line-height: 26px;
            color: $textGray;
        }

        a {
            color: $blue;
        }
    }

    &__highlight {
        width: 50%;
        max-width: 370px;
        margin-left: auto;
        background: rgba($blue, 0.1);
        padding: 20px;
        text-align: center;

        @include below(770px) {
            width: 100%;
            margin: 60px auto;
        }

        p {
            color: $blue;
            font-size: 26px;
            line-height: 32px;
            font-weight: $bold;
            font-family: $heading;
        }
    }
}
