.button {
    background-color: $blue;
    padding: 13px 33px;
    display: inline-block;
	border-radius: 70px;
	text-decoration: none;
	color: $white;
	font-family: $text;
	font-size: 20px;
	transition: all .2s ease-in-out;

    &--white {
        background-color: #fff;
		color: $blue;
    }

	&:hover {
		box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
	}
}

.form-btn {
	background-color: $white;
	color: $darkBlue;
	border: 0;
}

.linkedin-share {
	&__holder {
		width: 100%;
		max-width: 1100px;
		margin: 65px auto;

		@include below(1160px) {
			padding: 0 20px;
		}
		@include below(500px) {
			margin: 40px auto;
		}
	}

	&__btn {
    width: 36px;
	height: 36px;
	border-radius: 100%;
	background-color: $blue;
	display: block;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url('../img/linkedinlogo.svg');
	transition: transform .2s ease-in-out, background-color .1s ease-in-out;
	will-change: transform;

		&:hover {
			transform: translateY(-3px);
			background-color: rgba($blue, 0.9);
		}
	}
}
