.InfoSection {
    //
    background-color: $lightBlue;
    padding: 2rem 1rem;
    @include above($m) {
        padding: 5rem 2rem;

    }
    &-wrap {
        max-width: $content-max-width;
        margin-left: auto;
        margin-right: auto;
    }
    &
    &-image {
        padding-bottom: 3rem;;
        &-element {
            img {
                max-width: 100%;
                display: block;
                width: 100%;
            }
            @include above($s) {
                margin-left: auto;
            }
            @include below($s) {
                max-width: 100%;
                display: block;
                width: 100%;

            }
        }

    }
    &-heading {
        font-size: $page-heading-size;
        line-height: 1.1;
        color: $darkBlue;
        font-weight: $bold;
        font-family: $heading;
        padding-bottom: 3rem;
        @include below($m) {
            font-size: $page-heading-size--small;
            line-height: 1.1;
        }
    }

    &-text {
        color: $darkBlue;
        font-size: 1.125rem;
        line-height: 1.44;
        padding-bottom: 3rem;

        @include above($s) {
            margin-left: auto;
        }
    }

    &-expandable-blocks {
        @include above($s) {
            margin-left: auto;
        }

        &-heading {
            font-family: $heading;
            font-size: $body-text-size;
            line-height: 1;
            padding-bottom: 2rem;
        }

        &-block {
            background-color: $white;
            margin-bottom: 1rem;
            &-header {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
                padding: 1.5rem 1.25rem;
                font-size: 1rem;
                cursor: pointer;
            }

            &-content {
                padding: 1rem 1.25rem 1.5rem 1.25rem;
                p {
                    font-size: 1rem;
                    line-height: 1.4;
                }
            }
        }
    }
}