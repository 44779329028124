.sign-up-form {
    width: 100%;
    max-width: 920px;
    margin: 100px auto;
	@include above(930px) {
		padding-left: 1.5rem;
	}
    @include below(930px) {
        padding: 0 1.25rem;
    }

    &__title {
		font-size: 2rem;
		line-height: 1.5;
		font-weight: bold;
		margin-bottom: 1.5rem;

		@include below(620px) {
			font-size: 1.5rem;
		}
		@include below(370px) {
			font-size: 1.25rem;
		}
    }

    &__text {
        font-size: 1.125rem;
        line-height: 1.3;
        color: #575757;
    }

    &__form-holder {
        display: inline-block;
        margin: 50px auto;
        width: 100%;

        .wpcf7-form-control-wrap {
            display: block;
        }
    }

    &__column {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;

        @include below(620px) {
            flex-direction: column;
        }

        span {
            width: 50%;

            @include below(620px) {
                width: 100%;
            }

            &:first-of-type {
                margin-right: 30px;

                @include below(620px) {
                    margin-right: 0;
                }
            }
        }
    }

    input {
        width: 100%;
        border: none;
        padding: 10px 0;
        outline: none;
        border-bottom: 1px solid $blue;
    }

    input[type="submit"] {
        width: 50%;
        padding: 13px 33px;
        display: inline-block;
        border-radius: 70px;
        text-decoration: none;
        font-family: $text;
        font-size: 20px;
        transition: all .2s ease-in-out;
        background-color: $blue;
        color: #fff;
        border: 0;
        height: auto;
        float: right;
        margin-top: 20px;

        @include below(620px) {
            width: 100%;
            margin-top: 15px;
        }

        &:hover {
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
        }
    }
}
