.start-header {
    height: 840px;
    width: 100%;
    background-size: cover;
    position: relative;
    overflow: hidden;
    // background-position: center;

    display: flex;
    align-items: center;
    justify-content: center;

    @include below(1500px) {
        height: 540px;
    }
    &.has-video {
        @include below(700px) {
            height: 400px;
        }
    }
}

.start-header__inner {
    display: flex;
    align-items: center;
    vertical-align: baseline;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 2500px;
        position: absolute;
        background: #fff;
        height: 16px;
        left: 100%;
        margin-top: 14px;
        transform: skewX(23deg);
    }

    &:after {
        content: '';
        display: block;
        width: 2500px;
        position: absolute;
        background: #fff;
        height: 16px;
        right: 100%;
        margin-top: 14px;
        transform: skewX(-23deg);
    }
}
.start-header-video {
    position: absolute;
    @include below(700px) {
        height: 400px;
    }
}
