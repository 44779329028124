.Image-block {
	max-width: $content-max-width;
	width: 100%;
	margin: auto;
	padding: 2rem 0;  /* 120/16 */

	@include below($content-max-width) {
		margin: 1rem 0;
		padding: 0 $content-padding;
	}

	&__image {
		max-width: 100%;
		&-element {
			max-width: 100%;
		}
	}
}
