.NextCaseTeaser {
	//

	a {
		text-decoration: none;
	}
	
	&__no-background-image {
		@include background-svg($svg-pattern);
	}
	text-align: center;



	&__holder {
		color: $white;
		background-color: $blue;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		display: flex;
		flex-flow: row wrap;
		align-content: center;
		min-height: 444px;
		overflow: hidden;
		position: relative;
	}

	&__wrap {
		max-width: $content-max-width;
		width: 100%;
		margin: auto;
		padding: 3rem 0;  /* 120/16 */

		@include below($content-max-width) {
			margin: 3rem 0;
			padding: 0 $content-padding;
		}

			@include below($s) {
				padding-bottom: 3rem;
				// border-bottom: solid 8px $white;
				display: flex;
				align-items: center;
			}

	}
	&__heading {
		color: inherit;
		font-family: $heading;
		font-size: $page-heading-size;
		line-height: 1.2;
		width: auto;
		display: inline-block;
		position: relative;

		@include below($s) {
			font-size: $page-heading-size--small;
		}


			&:before {
		        content: "";
				margin-left: 2rem;
		        display: block;
		        width: 2500px;
		        background: #fff;
		        height: 8px;
		        left: 100%;
		        position: absolute;
		        top: 50%;
		        transform: translateY(-50%);

		        @include below($s) {
		            display: none;
		        }
		    }

		    &:after {
		        content: "";
						margin-right: 2rem;
		        display: block;
		        width: 2500px;
		        background: #fff;
		        height: 8px;
		        right: 100%;
		        position: absolute;
		        top: 50%;
		        transform: translateY(-50%);

		        @include below($s) {
		            display: none;
		        }
		    }
	}
	&__link-button {
		display: inline-block;
		position: relative;
		transform: translateY(50%);
		margin: auto;
		z-index: 99;
	}
	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom:0;
		background-image: linear-gradient(-180deg, rgba($white, 0.4) 0%, $white 100%);
		transition: opacity 0.8s linear;

		.NextCaseTeaser:hover & {
			opacity: 0;
		}
	}
}
