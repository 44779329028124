.image-link-list {
    width: 100%;
    max-width: 1100px;
    margin: 113px auto;

    @include below(1160px) {
        padding: 0 20px;
    }

    &__title {
        font-size: $body-text-size;
        line-height: 26px;
        color: $grey;
        margin-bottom: 55px;
        display: block;

        @include below(760px) {
            margin-bottom: 40px;
        }
    }
	&__featured-post {
        position: relative;

        @include below($m) {
            padding-bottom: 2rem;
        }
	}


        &__featured-post-link {
            display: block;
            width: 100%;

        }
		&__featured-post-image {
			display: block;
			width: 100%;
			height: 0;
			position: relative;
			background: $blue;
			overflow: hidden;
			&-element {
				position: absolute;
			}

            @include below($m) {
                width: 100%;
				&-element {
					width: 100%;
				}
            }
		}
		&__featured-post-heading {
             font-size: $page-content-heading-size;
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            width: 100%;
            color: $white;
            padding-left: 1rem;
            padding-bottom: 1rem;
		}
        .text-list__list {
            margin-left: auto;
            @include above($content-max-width) {
                max-width: 450px;

            }
            @include below($content-max-width) {
                padding: 0 0 0 20px;
            }

            @include below($m) {
                width: 100%;
                max-width: 100%;
                margin-left: 0;
                padding: 0;
            }

            &-item:last-of-type {
                border-bottom: 1px solid $grey;
            }
        }
}
