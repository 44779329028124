.partner-block {
    width: 100%;
    max-width: 1100px;
    margin: 87px auto;

    @include below(1160px) {
        padding: 0 20px;
    }

    @include below(500px) {
        margin: 60px auto 100px auto;
    }

    &__heading {
        font-size: $page-heading-size;
        line-height: 68px;
        color: #000;
        margin-bottom: 60px;
        font-weight: $bold;
		font-family: $heading;

        @include below(500px) {
            font-size: $page-content-heading-size;
            line-height: 43px;
            margin-bottom: 40px;
        }
    }

    &__partner {
        width: 100%;
        max-width: 730px;
        margin-bottom: 60px;

        &-logo {
            margin-bottom: 30px;
            display: block;
        }

        &-name {
            font-size: $page-content-heading-size;
            line-height: 43px;
            color: #000;
            font-weight: bold;
            margin-bottom: 20px;

            @include below(500px) {
                font-size: 26px;
                line-height: 32px;
            }
        }

        &-desc {
            font-size: 20px;
            line-height: 26px;
            color: $textGray;
        }
    }
}
