.big-image-usp {
    width: 100%;
    max-width: 1360px;
    min-height: 540px;
    margin: 125px auto 110px auto;
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 1px;

    @include below(1360px) {
        width: auto;
        margin: 125px 20px 110px 20px;
    }

    @include below(620px) {
        margin: 60px 20px;
    }

    @include below(500px) {
        height: 366px;
    }
}

.big-image-usp__inner {
    padding: 0 0 0 10%;

    @include below(620px) {
        position: absolute;
        padding: 20px;
        bottom: 0;
        top: auto;
        transform: none;
    }
}

.big-image-usp__title {
    font-size: 56px;
    font-weight: $bold;
    font-family: $heading;
    line-height: 68px;
    color: #fff;
    vertical-align: baseline;
    position: relative;
    display: inline-block;
    padding: 0 30px;

    @include below(620px) {
        padding: 0;
        font-size: 26px;
        line-height: 31px;
    }

    &:before {
        content: '';
        display: block;
        width: 2500px;
        background: #fff;
        height: 8px;
        left: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include below(620px) {
            display: none;
        }
    }

    &:after {
        content: '';
        display: block;
        width: 2500px;
        background: #fff;
        height: 8px;
        right: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include below(620px) {
            display: none;
        }
    }
}

.big-image-usp__link {
    display: block;
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    font-weight: $regular;
    margin-top: 25px;
    padding-left: 33px;
    text-decoration: none;

    @include below(620px) {
        padding: 0;
        margin-top: 15px;
    }

    &:hover {
        text-decoration: underline;
    }

    &:before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 11px;
        margin-right: 8px;
        background-image: url('../img/white-arrow-right.svg');

        @include below(620px) {
            display: none;
        }
    }
}
