
.ColumnBlocksBlock {
    //
    padding-top: 4rem;
    margin-top: 4rem;
    border-top: solid 1px $gray;
    max-width: $content-max-width;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4rem;

    &-image {
        max-width: 100%;
        width: 100%;
        display: block;
        img {
            width: inherit;
            max-width: inherit;
            display: block;
        }
    }
    &-column {
        align-items: center;
    }
    &-image-column {
        @include below($s) {
            padding-bottom: 2rem;
        }
    }
    &-text-column {
        display: flex;
        flex-flow: column;
        flex-wrap: wrap;
        justify-content: center;
    }
    &-text-wrap {
        display: block;
        width: 100%;
        @include below($s) {
            padding-bottom: 2rem;
        }
        @include above($s) {
            padding-left: 2rem;
            padding-right: 0rem;
        }
        @include above($m) {
            padding-left: 3rem;
        }
    }

    &-subheading {
        color: $blue;
        font-size: $body-text-size;
        padding-bottom: 1rem;
    }
    &-heading {
        color: $black;
        font-size: $page-content-heading-size;
        padding-bottom: 1rem;

    }
    &-text {
        color: $black;
        font-size: $body-text-size;
    }
}

.ColumnBlocksBlock-row {
    width: 100%;
    padding-bottom: 4rem;
    @include below($s) {
        flex-wrap: wrap;
    }

    &:nth-child(even) {
        .ColumnBlocksBlock-column:first-child {
            @include above($s){
                order: 2;
            }
        }
        .ColumnBlocksBlock-column:last-child {

            @include above($s){
                order: 1;
            }
            .ColumnBlocksBlock-text-wrap {
                @include above($s) {
                    padding-left: 0rem;
                    padding-right: 2rem;
                }
                @include above($m) {
                    padding-right: 3rem;
                }
            }
        }
    }
}

.ColumnBlocksBlock-column {
    //
}
