.text-list {
    width: 100%;
    max-width: 1100px;
    margin: 102px auto 115px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include below(1160px) {
        padding: 0 20px;
    }

    @include below(760px) {
        flex-direction: column;
    }
}

.text-list__content {
    max-width: 450px;

    @include below(1160px) {
        padding: 0 40px 0 0;
    }

    @include below(760px) {
        max-width: none;
        margin-bottom: 40px;
        padding: 0;
    }
}

.text-list__text-title {
    font-size: 56px;
    line-height: 68px;
    color: #000;
    font-weight: $bold;
	font-family: $heading;
    margin-bottom: 30px;

    @include below(500px) {
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 20px;
    }
}

.text-list__text {
    font-size: 20px;
    line-height: 26px;
    font-weight: $regular;
}

.text-list__list {
    width: 50%;
    max-width: 544px;

    @include below(760px) {
        width: 100%;
        max-width: none;
    }
}

.text-list__list-item {
    list-style: none;

    &--last {
        .text-list__list-link {
            color: $blue;
            font-weight: $regular;
            font-size: 20px;
            border-bottom: 1px solid $grey;
            padding: 42px 0;
        }
    }
}

.text-list__list-link {
    padding: 30px 0;
    border-top: 1px solid $grey;
    text-decoration: none;
    display: block;
    font-size: 20px;
    position: relative;
    padding-right: 30px;

    &:after {
        content: '';
        width: 24px;
        height: 15px;
        right: 0;
        @include vertical-align(absolute);
        background-image: url('../img/left_arrow.svg');
        transition: right .1s cubic-bezier(0,0,.2,1);
    }

    &:hover {
        &:after {
            right: 10px;
        }
    }
}

.text-list__list-subtitle {
    color: $grey;
    line-height: 24px;
    font-weight: $regular;
}

.text-list__list-title {
    color: #000;
    font-weight: $bold;
    font-family: $heading;
}
