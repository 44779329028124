.MultipleHeadings {
    //
    text-align: center;
    width: 100%;
    padding: 5rem 1rem;

    &-small-heading {
        color: $blue;
        font-size: $text-base;
        padding-bottom: 2rem;
    }

    &-headings {
        max-width: $m;
        margin:auto;
    }

    &-large-heading {
        font-size: $text-lg;
        color: $black;
        &:not(:last-child) {
            padding-bottom: 1.5rem;
        }
    }
}