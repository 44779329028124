.accordion {
    width: 100%;
    max-width: 1100px;
    margin: 40px auto 60px auto;

    @include below(1160px) {
        padding: 0 20px;
    }

    .accordion__item:last-of-type {
        border-bottom: 1px solid $grey;
    }
}

.accordion__item {
    width: 100%;
    max-width: 710px;
    border-top: 1px solid $grey;

    &.is-open {
        .accordion__text {
            overflow: auto;
            height: auto;
            margin: 0 0 50px 0;
        }

        .accordion__header:after {
            transform: rotate(180deg);
        }
    }
}

.accordion__header {
    padding: 30px 0;
    font-size: 20px;
    line-height: 24px;
    color: #000;
	font-family: $heading;
    font-weight: $bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    span {
        max-width: 80%;
    }

    &:after {
        content: '';
        width: 29px;
        height: 16px;
        display: inline-block;
        margin-right: 20px;
        background-image: url('../img/bluedownarrow.svg');
        transition: transform .2s cubic-bezier(0,0,.2,1);
        will-change: transform;
    }
}

.accordion__text {
    font-size: 20px;
    line-height: 26px;
    color: $textGray;
    height: 0;
    max-width: 535px;
    overflow: hidden;
    transition: all .2s cubic-bezier(0,0,.2,1);
}
