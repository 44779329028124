.big-usp-buttons {
    width: 100%;
    max-width: 1360px;
    min-height: 540px;
    margin: 137px auto 102px auto;
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 1px;

    @include below(620px) {
        height: auto;
        min-height: 366px;
        justify-content: center;
        margin: 50px auto;
    }
}

.big-usp-buttons__inner {
    padding: 0 0 0 10%;

    @include below(620px) {
        padding: 20px;
        top: auto;
        transform: none;
    }
}

.big-usp-buttons__title {
    font-size: 56px;
    font-weight: $bold;
	font-family: $heading;
    line-height: 68px;
    color: #fff;

    @include below(620px) {
        padding: 0;
        font-size: 26px;
        line-height: 31px;
    }
}

.big-usp-buttons__subtitle {
    display: block;
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    font-weight: $regular;
    margin: 10px 0 22px 0;
}

.big-usp-buttons__holder {
    vertical-align: baseline;
    position: relative;
    display: inline-block;
    padding: 0 35px 0 0;

    &:before {
        content: '';
        display: block;
        width: 2500px;
        background: #fff;
        height: 8px;
        left: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include below(620px) {
            display: none;
        }
    }

    &:after {
        content: '';
        display: block;
        width: 2500px;
        background: #fff;
        height: 8px;
        right: 105%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include below(620px) {
            display: none;
        }
    }

    .big-usp-buttons__button:last-of-type {
        margin-right: 0;
    }
}

.big-usp-buttons__button {
    background: #fff;
    border-radius: 100px;
    font-size: 20px;
    line-height: 26px;
    padding: 6px 20px 6px 15px;
    margin-right: 20px;
    display: inline-block;
    box-shadow: 0 10px 25px 0 rgba(0,0,0,0.08);
    transition: background .1s ease-in-out;

    &:hover {
        background: rgba(#fff, 0.85);
    }

    @include below(900px) {
        display: block;
        margin-bottom: 20px;
    }

    a {
        color: #000;
        text-decoration: none;

        &:before {
            content: '';
            width: 7px;
            height: 7px;
            border-radius: 100%;
            background: #E2B881;
            display: inline-block;
            vertical-align: middle;
            margin: -4px 12px 0 0;
            transition: width .1s ease-in-out, height .1s ease-in-out;
        }
    }
}
