// packages

.package-block {
    &:last-of-type {
        margin-bottom: 100px;
    }

    @include below(1160px) {
        padding: 0 20px;
    }
}

.package-block_inner {
    width: 100%;
    max-width: $content-max-width;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $grey;

    @include below(760px) {
        flex-direction: column;
    }
}

.package-block__big-title {
    font-size: 56px;
    font-weight: $bold;
	font-family: $heading;
    padding: 0 0 50px 0;
    max-width: 1100px;
    margin: 0 auto;
    border-bottom: 1px solid $grey;

    @include below(1160px) {
        margin: 0 20px;
    }

    @include below(500px) {
        font-size: 36px;
        line-height: 43px;
    }
}

.package-block__content {
    width: 50%;
    max-width: 450px;
    padding: 0 20px 0 0;

    @include below(760px) {
        width: 100%;
        max-width: none;
        padding: 0;
    }

    @include below(500px) {
        .package-block__subtitle {
            margin-top: 20px;
        }
    }
}

.package-block__subtitle {
    font-size: 20px;
    line-height: 24px;
    color: $textGray;
    margin: 47px 0;
    display: block;
}

.package-block__title {
    font-size: 36px;
    line-height: 43px;
    color: #000;
    font-weight: $bold;
	font-family: $heading;
    margin-bottom: 20px;
}

.package-block__text {
    font-size: 20px;
    line-height: 26px;
    color: $textGray;
}

.package-block__list-holder {
    width: 50%;
    max-width: 545px;

    @include below(760px) {
        width: 100%;
        max-width: none;
    }
}

.package-block__list {
    width: 100%;
}

.package-block__list-item {
    list-style: none;
}

.package-block__list-link {
    font-size: 20px;
    list-style: 24px;
    color: $blue;
    font-weight: $bold;
    font-family: $heading;
    padding: 42px 0;
    display: block;
    text-decoration: none;
    border-top: 1px solid $grey;
    position: relative;
    padding-right: 30px;

    &:after {
        content: '';
        width: 24px;
        height: 15px;
        right: 0;
        @include vertical-align(absolute);
        background-image: url('../img/left_arrow.svg');
        transition: right .1s cubic-bezier(0,0,.2,1);
    }

    &:hover {
        &:after {
            right: 10px;
        }
    }
}
