.center-image-quote {
    width: 100%;
    max-width: 915px;
    margin: 147px auto;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
	@include below($content-max-width) {
		padding: 0 1rem;
	}
    @include below(800px) {
        margin: 55px auto;
    }

	&__image-holder {
		text-align: center;
	}
	&__image {
		margin: auto;
        border-radius: 100%;
        @include below($s) {
            margin-bottom: 2rem;
        }
	}
    &__content {
        margin-left: 0;
        max-width: 670px;
        margin-left: auto;
        padding-left: 30px;

        &--no-image {
            max-width: 670px;
            margin: auto;
            padding-left: 0;
        }
    }
    &__text {
        color: $blue;
        font-size: 36px;
        line-height: 1.2;
        font-weight: $bold;
		font-family: $heading;
		margin: 0 0 10px 0;
		@include below($s) {
		padding: 0 1rem;
		-ms-word-break: normal;
    	word-break: normal;

    	/* Non standard for WebKit */
    	word-break: break-word;

    -webkit-hyphens: auto;
      -moz-hyphens: auto;
    	   hyphens: auto;
		}
    }
    &__author {
        font-size: 20px;
        line-height: 2.1;
        color: $textGray;
        display: block;
    }
}
