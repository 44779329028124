/**
 * May this be a helping hand in the land of media queries.
 * Nothing is a waste of time if you use the experience wisely.
 */


@mixin below($screen-size, $rotation: false) {
	@if( $rotation == landscape or $rotation == portrait ) {
		@media only screen and (max-width: $screen-size) and (orientation : $rotation) { @content; }
	} @else {
		@media only screen and (max-width: $screen-size)  { @content; }

	}
}

@mixin above($screen-size, $rotation: false) {

	@if( $rotation == landscape or $rotation == portrait ) {
		@media only screen and (min-width: $screen-size) and (orientation : $rotation) { @content; }
	} @else {
		@media only screen and (min-width: $screen-size)  { @content; }
	}
}
@mixin between($min-size, $max-size, $rotation: false) {

	@if( $rotation == landscape or $rotation == portrait ) {
		@media only screen and (max-width: $max-size) and (min-width:$min-size) and (orientation : $rotation) { @content; }
	} @else {
		@media only screen and (max-width: $max-size) and (min-width:$min-size) {
			@content;
		}
	}
}
/**
 * Retina
 */
@mixin retina() {
	@media only screen and (-webkit-min-device-pixel-ratio : 1.5 ),
	only screen and (min-device-pixel-ratio : 1.5) { @content; }
}

//
@mixin higher($screen-size, $rotation: false){

	@if( $rotation == landscape or $rotation == portrait ) {
		@media only screen and (min-height: $screen-size) and (orientation : $rotation) { @content; }
	} @else {
		@media only screen and (min-height: $screen-size)  { @content; }
	}
}

@mixin lower( $screen-size, $rotation: false ){
	@if( $rotation == landscape or $rotation == portrait ) {
		@media only screen and (max-height: $screen-size) and (orientation : $rotation) { @content; }
	} @else {
		@media only screen and (max-height: $screen-size)  { @content; }
	}
}


@mixin phone-only(){
		@media only screen and (max-width: 799px) and (-webkit-min-device-pixel-ratio: 3) {
			@content;
		}
}
