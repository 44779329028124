.big-image-grid {
    width: 100%;
    max-width: 1114px;
    margin: 114px auto;

    &__container {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__title {
        font-size: $body-text-size;
        line-height: 26px;
        color: $grey;
        margin-bottom: 55px;
        display: block;
        padding-left: 7px;

        @include below(760px) {
            margin-bottom: 40px;
            padding-left: 20px;
        }
    }

    &__card-holder {
		margin-bottom: 1rem;
    }

	&__card-image {
		position: relative;
        background-color: $blue;
        display: block;
        height: auto;
        width: 100%;
        max-width: 100%;
        height: 0;
        overflow: hidden;
		padding-bottom: 103%; // 561/544 * 100
		@include below(415px) {
			padding-bottom: 100%;

		}
        &-element {
			position: absolute;
            display: block;
            width: 100%;
            max-width: 100%;
        }
	}

    &__card {
        width: 100%;
        height: 100%;
        display: block;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        transition: opacity 0.8s linear;

        &-tag {
            padding: 0.625rem 1.25rem 0.625rem 1.25rem; /* 10/16 */ /* 10/16 */
            background: #fff;
            border-radius: 50px;
            color: #000;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            position: relative;
            margin: 10px 0 0 10px;
            display: inline-block;
			position: absolute;
            top: 0;
            left:0;

            &-text {
                display: inline-block;
                &:before {
                    content: "";
                    // position: absolute;
                    // left: 20px;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background: $blue;
                    display: inline-block;
                    margin-right: 1rem;
                    // top: 50%;
                    // transform: translateY(-50%);
                }
            }


        }

        &-title {
            font-size: $page-heading-size--small;
            line-height: 43px;
            color: #fff;
            position: absolute;
            bottom: 40px;
            left: 40px;
            width: 100%;
            max-width: 410px;
            z-index: 1;
            font-weight: bold;
            position: absolute;
            bottom: 0;
            left: 0;
            max-width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;


            @include below($m) {
                font-size: 26px;
                line-height: 1.19;
                bottom: 20px;
                left: 20px;
                width: 80%;
            }
        }

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom:0;
            opacity: 0;
            z-index: 0;
            background-image: linear-gradient(-180deg, rgba(#000, 0.01) 0%, #000 130%);
            transition: opacity 0.4s linear;

            .big-image-grid__card:hover & {
                opacity: 1;
            }
        }
    }

}
