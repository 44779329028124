.contact-persons {
    width: 100%;
    max-width: 1360px;
    margin: 90px auto;
    background: rgba($blue, 0.1);
    padding: 95px 20px 165px 20px;

    @include below(414px) {
        padding: 40px 20px;
        margin: 60px auto;
    }

    &__heading {
        font-size: $page-heading-size;
        line-height: 68px;
        color: $blue;
        font-weight: $bold;
		font-family: $heading;
        text-align: center;
        margin-bottom: 88px;

        @include below(414px) {
            font-size: $page-content-heading-size;
            line-height: 42px;
            margin-bottom: 50px;
        }
    }

    &__grid {
        width: 100%;
        margin: 0 auto;
        max-width: $content-max-width;
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        align-items: center;

        &-card {
            width: calc(100% / 6);
            padding: 0 6px;

            @include below(870px) {
                width: calc(100% / 4);
                padding: 15px 6px;
            }

            @include below(500px) {
                width: calc(100% / 2);
            }

            @include below(414px) {
                width: 100%;
            }

            img {
                max-width: 100%;
                display: block;
            }

            span {
                display: block;
            }

            a {
                display: block;
            }
        }
    }

    &__name {
        color: $blue;
        font-weight: $bold;
        font-family: $heading;
        font-size: 16px;
        line-height: 19px;
        margin: 20px 0 12px 0;
    }

    &__title,
    &__phone {
        color: #000;
        margin-bottom: 10px;
        font-weight: $bold;
        font-family: $heading;
        text-decoration: none;
        font-size: 14px;
        line-height: 17px;
    }

    &__mail {
        color: $blue;
        font-weight: $bold;
        font-family: $heading;
        font-size: 14px;
        line-height: 17px;
        text-decoration: underline;
    }
}
