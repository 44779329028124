@import "shared/constants";
@import "shared/normalize";
@import "shared/breaker";
@import "shared/mixins";
@import "shared/flickity";

//Global
@import "shared/buttons";
@import "shared/grid";

@import "components/SiteFooter";


// Startpage
@import "components/startpage/StartHeader";
@import "components/startpage/TitleTextBlock";
@import "components/startpage/BigImageUsp";
@import "components/startpage/TextColumns";
@import "components/startpage/BigUspButtons";
@import "components/startpage/TextListBlock";

/*
*** AddSoucedPage
*/
@import "components/addsourced/index.scss";

/*
*** AddSoucedPage
*/

// Components
@import "components/PackageBlock";
@import "components/SmallTextBlockQuote";
@import "components/AccordionBlock";
@import "components/RelatedBlock";
@import "components/BottomMenu";
@import "components/CaseColumn";
@import "components/CenterImageQuote";
@import "components/CaseTopHeader";
@import "components/ImageCenterTextButton";
@import "components/BlueIngressBlock";
@import "components/LargeHeadingBlock";
@import "components/CaseFlowColumns";
@import "components/LargeBlockQuote";
@import "components/TextHeadingBlock";
@import "components/ImageLinkList";
@import "components/ContactPersons";
@import "components/BigImageGrid";
@import "components/CareerLinkBlock";
@import "components/NextCaseTeaser";
@import "components/PartnerBlock";
@import "components/TextHeadingHighlight";
@import "components/ArticleHeader";
@import "components/MapsBlock";
@import "components/ImageBlock";
@import "components/VideoBlock";
@import "components/WhitePaperForm";
@import "components/InstagramFeedBlock";
@import "components/SignUpForm";
@import "components/EventSchedule";
@import "components/EventPersons";
@import "components/EventText";


html {
    scroll-behavior: smooth;
  }


body {
	padding-top: 65px;
    font-family: $text;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.text-align {
	&--left {
		text-align: left;
	}
	&--right {
		text-align: right;
	}
	&--center {
		text-align: center;
	}
}
