.PageAnchorMenu {
    padding: 0 2rem;
    @include below($s){
        padding: 0 1rem;
    }
    &-wrap {
        margin: 0 auto;
        max-width: $content-max-width;
        width: 100%;
        border-bottom: solid 1px $gray;
        padding-top: 2rem;
        text-align: center;
    }

    &-item {
        display: inline-block;
        padding: 0 1rem 2rem 1rem;
    }

    &-link {
        color: $black;
        font-family: $text;
        font-size: 1.125rem;
        line-height: 1.1;
        text-decoration: none;


        &:hover {
            text-decoration: underline;
        }
    }
}