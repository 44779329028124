.case-flow-columns {
    width: 100%;
    max-width: $content-max-width;
    margin: 80px auto 100px auto;
}

.case-flow-columns__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .case-flow-columns__column:last-of-type {
        &:after {
            width: 0;
        }
    }
}

.case-flow-columns__column {
    width: calc(100% / 4);
    padding: 0 7px;
    display: flex;
    position: relative;

    @include below(800px) {
        width: calc(100% / 2);
        padding: 10px;

        &:nth-child(even) {
            &:after {
                width: 0;
            }
        }
    }
    @include below(500px) {
        width: 100%;

        &:nth-child(even) {
            &:after {
                width: 5px;
            }
        }
    }

    &:after {
        content: '';
        width: 100%;
        display: block;
        height: 5px;
        background: $blue;
        z-index: -1;
        @include vertical-align(absolute);

        @include below(500px) {
            width: 5px;
            height: 100%;
            transform: none;
            margin: 0 auto;
            left: 0;
            right: 0;
        }
    }
}

.case-flow-columns__column-inner {
    background: #FFFFFF;
    padding: 94px 20px;
    text-align: center;
    display: flex;
    align-items: center;
    box-shadow: 0 20px 40px 0 rgba(0,0,0,0.06);

    @include below(500px) {
        padding: 70px 20px;
    }

    p {
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.22px;
    }
}
