.text-heading-block {
    width: 100%;
    max-width: $content-max-width;
    margin: 0 auto;

    @include below(1160px) {
        padding: 0 20px;
    }

    h3 {
        font-size: $page-heading-size--small;
        line-height: 43px;
        letter-spacing: 0.43px;
        font-weight: $bold;
		font-family: $heading;
        margin-bottom: 20px;
        max-width: 585px;

        @include below(500px) {
            font-size: 22px;
            line-height: 28px;
        }
    }

    &__content {
        width: 60%;
        max-width: 585px;
        color: $textGray;
        line-height: 26px;
        margin: 30px 0 56px 0;
        font-size: $body-text-size;

        ul {
            padding: 0 40px;
        }

        p {
            margin: 20px 0;
        }

        a {
            color: $blue;
        }

        @include below(500px) {
            width: 100%;
        }
    }
}
