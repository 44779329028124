/* 56/16 */
/* 36/16 */
/* 36/16 */
/* 20/16 */
/* 28/16 */
/* 28/16 */
/* 26/16 */
/* 1100/16 */
/**
 * May this be a helping hand in the land of media queries.
 * Nothing is a waste of time if you use the experience wisely.
 */
/**
 * Retina
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased; }
  body * {
    box-sizing: border-box; }

a:focus {
  outline: 0; }

a:active,
a:hover {
  outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.clearfix {
  display: inline-block; }

html[xmlns] .clearfix {
  display: block; }

* html .clearfix {
  height: 1%; }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal; }

ul {
  margin: 0;
  padding: 0; }

/**
 * May this be a helping hand in the land of media queries.
 * Nothing is a waste of time if you use the experience wisely.
 */
/**
 * Retina
 */
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.button {
  background-color: #2385FC;
  padding: 13px 33px;
  display: inline-block;
  border-radius: 70px;
  text-decoration: none;
  color: #fff;
  font-family: "Lab Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 20px;
  transition: all .2s ease-in-out; }
  .button--white {
    background-color: #fff;
    color: #2385FC; }
  .button:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2); }

.form-btn {
  background-color: #fff;
  color: #2346B5;
  border: 0; }

.linkedin-share__holder {
  width: 100%;
  max-width: 1100px;
  margin: 65px auto; }
  @media only screen and (max-width: 1160px) {
    .linkedin-share__holder {
      padding: 0 20px; } }
  @media only screen and (max-width: 500px) {
    .linkedin-share__holder {
      margin: 40px auto; } }

.linkedin-share__btn {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: #2385FC;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../img/linkedinlogo.svg");
  transition: transform .2s ease-in-out, background-color .1s ease-in-out;
  will-change: transform; }
  .linkedin-share__btn:hover {
    transform: translateY(-3px);
    background-color: rgba(35, 133, 252, 0.9); }

@lost gutter 2rem;
@lost cycle auto;
@lost rounder 99.69999;
.row {
  lost-utility: clearfix;
  width: 100%;
  margin: auto;
  z-index: 1;
  max-width: 68.75rem;
  display: block; }
  @media only screen and (max-width: 68.75rem) {
    .row {
      max-width: 100%;
      padding-left: 1rem;
      padding-right: 1rem; } }
  .row .row {
    padding: 0; }

.flex-row {
  lost-flex-container: row;
  width: 100%;
  margin: auto;
  z-index: 1;
  max-width: 68.75rem; }
  @media only screen and (max-width: 68.75rem) {
    .flex-row {
      max-width: 100%;
      padding-left: 1rem;
      padding-right: 1rem; } }
  .flex-row .flex-row {
    padding: 0; }

.grid {
  lost-utility: clearfix; }

.clear {
  lost-utility: clearfix; }

.justify--center {
  justify-content: center; }

.justify--space-between {
  justify-content: space-between; }

.justify--end {
  justify-content: flex-end; }

.justify--start {
  justify-content: flex-start; }

.align--vertical {
  lost-align: center flex;
  align-items: center; }

@media only screen and (min-width: 640px) {
  .align-columns--vertical {
    lost-align: center flex;
    align-items: center; } }

.col-center {
  float: none; }
  .col-center--large {
    lost-center: 68.75rem; }
    @media only screen and (max-width: 68.75rem) {
      .col-center--large {
        max-width: 90%; } }
  .col-center--medium {
    lost-center: 800px; }
    @media only screen and (max-width: 800px) {
      .col-center--medium {
        max-width: 90%; } }
  .col-center--small {
    lost-center: 420px; }
    @media only screen and (max-width: 420px) {
      .col-center--small {
        max-width: 90%; } }

.col--grow {
  flex-grow: 1 !important; }

/** Columns **/
.row .flex-col-1 {
  lost-column: 1/12 flex; }

.row .col-1 {
  lost-column: 1/12; }

@media only screen and (min-width: 800px) {
  .row .col-1-move--right {
    lost-move: 1/12 1rem; }
  .row .col-1-move--left {
    lost-move: -1/12 1rem; }
  .row .col-1-offset--right {
    lost-offset: 1/12 1rem; }
  .row .col-1-offset--left {
    lost-offset: -1/12 1rem; } }

.row .flex-col-2 {
  lost-column: 2/12 flex; }

.row .col-2 {
  lost-column: 2/12; }

@media only screen and (min-width: 800px) {
  .row .col-2-move--right {
    lost-move: 2/12 1rem; }
  .row .col-2-move--left {
    lost-move: -2/12 1rem; }
  .row .col-2-offset--right {
    lost-offset: 2/12 1rem; }
  .row .col-2-offset--left {
    lost-offset: -2/12 1rem; } }

.row .flex-col-3 {
  lost-column: 3/12 flex; }

.row .col-3 {
  lost-column: 3/12; }

@media only screen and (min-width: 800px) {
  .row .col-3-move--right {
    lost-move: 3/12 1rem; }
  .row .col-3-move--left {
    lost-move: -3/12 1rem; }
  .row .col-3-offset--right {
    lost-offset: 3/12 1rem; }
  .row .col-3-offset--left {
    lost-offset: -3/12 1rem; } }

.row .flex-col-4 {
  lost-column: 4/12 flex; }

.row .col-4 {
  lost-column: 4/12; }

@media only screen and (min-width: 800px) {
  .row .col-4-move--right {
    lost-move: 4/12 1rem; }
  .row .col-4-move--left {
    lost-move: -4/12 1rem; }
  .row .col-4-offset--right {
    lost-offset: 4/12 1rem; }
  .row .col-4-offset--left {
    lost-offset: -4/12 1rem; } }

.row .flex-col-5 {
  lost-column: 5/12 flex; }

.row .col-5 {
  lost-column: 5/12; }

@media only screen and (min-width: 800px) {
  .row .col-5-move--right {
    lost-move: 5/12 1rem; }
  .row .col-5-move--left {
    lost-move: -5/12 1rem; }
  .row .col-5-offset--right {
    lost-offset: 5/12 1rem; }
  .row .col-5-offset--left {
    lost-offset: -5/12 1rem; } }

.row .flex-col-6 {
  lost-column: 6/12 flex; }

.row .col-6 {
  lost-column: 6/12; }

@media only screen and (min-width: 800px) {
  .row .col-6-move--right {
    lost-move: 6/12 1rem; }
  .row .col-6-move--left {
    lost-move: -6/12 1rem; }
  .row .col-6-offset--right {
    lost-offset: 6/12 1rem; }
  .row .col-6-offset--left {
    lost-offset: -6/12 1rem; } }

.row .flex-col-7 {
  lost-column: 7/12 flex; }

.row .col-7 {
  lost-column: 7/12; }

@media only screen and (min-width: 800px) {
  .row .col-7-move--right {
    lost-move: 7/12 1rem; }
  .row .col-7-move--left {
    lost-move: -7/12 1rem; }
  .row .col-7-offset--right {
    lost-offset: 7/12 1rem; }
  .row .col-7-offset--left {
    lost-offset: -7/12 1rem; } }

.row .flex-col-8 {
  lost-column: 8/12 flex; }

.row .col-8 {
  lost-column: 8/12; }

@media only screen and (min-width: 800px) {
  .row .col-8-move--right {
    lost-move: 8/12 1rem; }
  .row .col-8-move--left {
    lost-move: -8/12 1rem; }
  .row .col-8-offset--right {
    lost-offset: 8/12 1rem; }
  .row .col-8-offset--left {
    lost-offset: -8/12 1rem; } }

.row .flex-col-9 {
  lost-column: 9/12 flex; }

.row .col-9 {
  lost-column: 9/12; }

@media only screen and (min-width: 800px) {
  .row .col-9-move--right {
    lost-move: 9/12 1rem; }
  .row .col-9-move--left {
    lost-move: -9/12 1rem; }
  .row .col-9-offset--right {
    lost-offset: 9/12 1rem; }
  .row .col-9-offset--left {
    lost-offset: -9/12 1rem; } }

.row .flex-col-10 {
  lost-column: 10/12 flex; }

.row .col-10 {
  lost-column: 10/12; }

@media only screen and (min-width: 800px) {
  .row .col-10-move--right {
    lost-move: 10/12 1rem; }
  .row .col-10-move--left {
    lost-move: -10/12 1rem; }
  .row .col-10-offset--right {
    lost-offset: 10/12 1rem; }
  .row .col-10-offset--left {
    lost-offset: -10/12 1rem; } }

.row .flex-col-11 {
  lost-column: 11/12 flex; }

.row .col-11 {
  lost-column: 11/12; }

@media only screen and (min-width: 800px) {
  .row .col-11-move--right {
    lost-move: 11/12 1rem; }
  .row .col-11-move--left {
    lost-move: -11/12 1rem; }
  .row .col-11-offset--right {
    lost-offset: 11/12 1rem; }
  .row .col-11-offset--left {
    lost-offset: -11/12 1rem; } }

.row .flex-col-12 {
  lost-column: 12/12 0 0 flex; }

.row .col-12 {
  lost-column: 12/12 0 0; }

@media only screen and (min-width: 800px) {
  .row .col-12-move--right {
    lost-move: 12/12 1rem; }
  .row .col-12-move--left {
    lost-move: -12/12 1rem; }
  .row .col-12-offset--right {
    lost-offset: 12/12 1rem; }
  .row .col-12-offset--left {
    lost-offset: -12/12 1rem; } }

@media only screen and (max-width: 800px) {
  .row .flex-col-1 {
    lost-column: 12/12 flex; }
  .row .col-1 {
    lost-column: 12/12; }
  .row .flex-col-2 {
    lost-column: 12/12 flex; }
  .row .col-2 {
    lost-column: 12/12; }
  .row .flex-col-3 {
    lost-column: 12/12 flex; }
  .row .col-3 {
    lost-column: 12/12; }
  .row .flex-col-4 {
    lost-column: 12/12 flex; }
  .row .col-4 {
    lost-column: 12/12; }
  .row .flex-col-5 {
    lost-column: 12/12 flex; }
  .row .col-5 {
    lost-column: 12/12; }
  .row .flex-col-6 {
    lost-column: 12/12 flex; }
  .row .col-6 {
    lost-column: 12/12; }
  .row .flex-col-7 {
    lost-column: 12/12 flex; }
  .row .col-7 {
    lost-column: 12/12; }
  .row .flex-col-8 {
    lost-column: 12/12 flex; }
  .row .col-8 {
    lost-column: 12/12; }
  .row .flex-col-9 {
    lost-column: 12/12 flex; }
  .row .col-9 {
    lost-column: 12/12; }
  .row .flex-col-10 {
    lost-column: 12/12 flex; }
  .row .col-10 {
    lost-column: 12/12; }
  .row .flex-col-11 {
    lost-column: 12/12 flex; }
  .row .col-11 {
    lost-column: 12/12; }
  .row .flex-col-12 {
    lost-column: 12/12 0 0 flex; }
  .row .col-12 {
    lost-column: 12/12 0 0; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .row .m-col-1 {
    lost-column: 1/12; }
  .row .m-flex-col-1 {
    lost-column: 1/12 flex; }
  .row .m-col-1-move--right {
    lost-move: 1/12 1rem; }
  .row .m-col-1-move--left {
    lost-move: -1/12 1rem; }
  .row .m-col-1-offset--right {
    lost-offset: 1/12 1rem; }
  .row .m-col-1-offset--left {
    lost-offset: -1/12 1rem; }
  .row .m-col-2 {
    lost-column: 2/12; }
  .row .m-flex-col-2 {
    lost-column: 2/12 flex; }
  .row .m-col-2-move--right {
    lost-move: 2/12 1rem; }
  .row .m-col-2-move--left {
    lost-move: -2/12 1rem; }
  .row .m-col-2-offset--right {
    lost-offset: 2/12 1rem; }
  .row .m-col-2-offset--left {
    lost-offset: -2/12 1rem; }
  .row .m-col-3 {
    lost-column: 3/12; }
  .row .m-flex-col-3 {
    lost-column: 3/12 flex; }
  .row .m-col-3-move--right {
    lost-move: 3/12 1rem; }
  .row .m-col-3-move--left {
    lost-move: -3/12 1rem; }
  .row .m-col-3-offset--right {
    lost-offset: 3/12 1rem; }
  .row .m-col-3-offset--left {
    lost-offset: -3/12 1rem; }
  .row .m-col-4 {
    lost-column: 4/12; }
  .row .m-flex-col-4 {
    lost-column: 4/12 flex; }
  .row .m-col-4-move--right {
    lost-move: 4/12 1rem; }
  .row .m-col-4-move--left {
    lost-move: -4/12 1rem; }
  .row .m-col-4-offset--right {
    lost-offset: 4/12 1rem; }
  .row .m-col-4-offset--left {
    lost-offset: -4/12 1rem; }
  .row .m-col-5 {
    lost-column: 5/12; }
  .row .m-flex-col-5 {
    lost-column: 5/12 flex; }
  .row .m-col-5-move--right {
    lost-move: 5/12 1rem; }
  .row .m-col-5-move--left {
    lost-move: -5/12 1rem; }
  .row .m-col-5-offset--right {
    lost-offset: 5/12 1rem; }
  .row .m-col-5-offset--left {
    lost-offset: -5/12 1rem; }
  .row .m-col-6 {
    lost-column: 6/12; }
  .row .m-flex-col-6 {
    lost-column: 6/12 flex; }
  .row .m-col-6-move--right {
    lost-move: 6/12 1rem; }
  .row .m-col-6-move--left {
    lost-move: -6/12 1rem; }
  .row .m-col-6-offset--right {
    lost-offset: 6/12 1rem; }
  .row .m-col-6-offset--left {
    lost-offset: -6/12 1rem; }
  .row .m-col-7 {
    lost-column: 7/12; }
  .row .m-flex-col-7 {
    lost-column: 7/12 flex; }
  .row .m-col-7-move--right {
    lost-move: 7/12 1rem; }
  .row .m-col-7-move--left {
    lost-move: -7/12 1rem; }
  .row .m-col-7-offset--right {
    lost-offset: 7/12 1rem; }
  .row .m-col-7-offset--left {
    lost-offset: -7/12 1rem; }
  .row .m-col-8 {
    lost-column: 8/12; }
  .row .m-flex-col-8 {
    lost-column: 8/12 flex; }
  .row .m-col-8-move--right {
    lost-move: 8/12 1rem; }
  .row .m-col-8-move--left {
    lost-move: -8/12 1rem; }
  .row .m-col-8-offset--right {
    lost-offset: 8/12 1rem; }
  .row .m-col-8-offset--left {
    lost-offset: -8/12 1rem; }
  .row .m-col-9 {
    lost-column: 9/12; }
  .row .m-flex-col-9 {
    lost-column: 9/12 flex; }
  .row .m-col-9-move--right {
    lost-move: 9/12 1rem; }
  .row .m-col-9-move--left {
    lost-move: -9/12 1rem; }
  .row .m-col-9-offset--right {
    lost-offset: 9/12 1rem; }
  .row .m-col-9-offset--left {
    lost-offset: -9/12 1rem; }
  .row .m-col-10 {
    lost-column: 10/12; }
  .row .m-flex-col-10 {
    lost-column: 10/12 flex; }
  .row .m-col-10-move--right {
    lost-move: 10/12 1rem; }
  .row .m-col-10-move--left {
    lost-move: -10/12 1rem; }
  .row .m-col-10-offset--right {
    lost-offset: 10/12 1rem; }
  .row .m-col-10-offset--left {
    lost-offset: -10/12 1rem; }
  .row .m-col-11 {
    lost-column: 11/12; }
  .row .m-flex-col-11 {
    lost-column: 11/12 flex; }
  .row .m-col-11-move--right {
    lost-move: 11/12 1rem; }
  .row .m-col-11-move--left {
    lost-move: -11/12 1rem; }
  .row .m-col-11-offset--right {
    lost-offset: 11/12 1rem; }
  .row .m-col-11-offset--left {
    lost-offset: -11/12 1rem; }
  .row .m-col-12 {
    lost-column: 12/12 0 0;
    float: none;
    width: 100%;
    max-width: 100%;
    margin: 0; }
  .row .m-flex-col-12 {
    lost-column: 12/12 0 0 flex; }
  .row .m-col-12-move--right {
    lost-move: 12/12 1rem; }
  .row .m-col-12-move--left {
    lost-move: -12/12 1rem; }
  .row .m-col-12-offset--right {
    lost-offset: 12/12 1rem; }
  .row .m-col-12-offset--left {
    lost-offset: -12/12 1rem; } }

@media only screen and (max-width: 640px) {
  .row .s-col-1 {
    lost-column: 1/12; }
  .row .s-flex-col-1 {
    lost-column: 1/12 flex; }
  .row .s-col-1-move--right {
    lost-move: 1/12; }
  .row .s-col-1-move--left {
    lost-move: -1/12; }
  .row .s-col-1-offset--right {
    lost-offset: 1/12; }
  .row .s-col-1-offset--left {
    lost-offset: -1/12; }
  .row .s-col-2 {
    lost-column: 2/12; }
  .row .s-flex-col-2 {
    lost-column: 2/12 flex; }
  .row .s-col-2-move--right {
    lost-move: 2/12; }
  .row .s-col-2-move--left {
    lost-move: -2/12; }
  .row .s-col-2-offset--right {
    lost-offset: 2/12; }
  .row .s-col-2-offset--left {
    lost-offset: -2/12; }
  .row .s-col-3 {
    lost-column: 3/12; }
  .row .s-flex-col-3 {
    lost-column: 3/12 flex; }
  .row .s-col-3-move--right {
    lost-move: 3/12; }
  .row .s-col-3-move--left {
    lost-move: -3/12; }
  .row .s-col-3-offset--right {
    lost-offset: 3/12; }
  .row .s-col-3-offset--left {
    lost-offset: -3/12; }
  .row .s-col-4 {
    lost-column: 4/12; }
  .row .s-flex-col-4 {
    lost-column: 4/12 flex; }
  .row .s-col-4-move--right {
    lost-move: 4/12; }
  .row .s-col-4-move--left {
    lost-move: -4/12; }
  .row .s-col-4-offset--right {
    lost-offset: 4/12; }
  .row .s-col-4-offset--left {
    lost-offset: -4/12; }
  .row .s-col-5 {
    lost-column: 5/12; }
  .row .s-flex-col-5 {
    lost-column: 5/12 flex; }
  .row .s-col-5-move--right {
    lost-move: 5/12; }
  .row .s-col-5-move--left {
    lost-move: -5/12; }
  .row .s-col-5-offset--right {
    lost-offset: 5/12; }
  .row .s-col-5-offset--left {
    lost-offset: -5/12; }
  .row .s-col-6 {
    lost-column: 6/12; }
  .row .s-flex-col-6 {
    lost-column: 6/12 flex; }
  .row .s-col-6-move--right {
    lost-move: 6/12; }
  .row .s-col-6-move--left {
    lost-move: -6/12; }
  .row .s-col-6-offset--right {
    lost-offset: 6/12; }
  .row .s-col-6-offset--left {
    lost-offset: -6/12; }
  .row .s-col-7 {
    lost-column: 7/12; }
  .row .s-flex-col-7 {
    lost-column: 7/12 flex; }
  .row .s-col-7-move--right {
    lost-move: 7/12; }
  .row .s-col-7-move--left {
    lost-move: -7/12; }
  .row .s-col-7-offset--right {
    lost-offset: 7/12; }
  .row .s-col-7-offset--left {
    lost-offset: -7/12; }
  .row .s-col-8 {
    lost-column: 8/12; }
  .row .s-flex-col-8 {
    lost-column: 8/12 flex; }
  .row .s-col-8-move--right {
    lost-move: 8/12; }
  .row .s-col-8-move--left {
    lost-move: -8/12; }
  .row .s-col-8-offset--right {
    lost-offset: 8/12; }
  .row .s-col-8-offset--left {
    lost-offset: -8/12; }
  .row .s-col-9 {
    lost-column: 9/12; }
  .row .s-flex-col-9 {
    lost-column: 9/12 flex; }
  .row .s-col-9-move--right {
    lost-move: 9/12; }
  .row .s-col-9-move--left {
    lost-move: -9/12; }
  .row .s-col-9-offset--right {
    lost-offset: 9/12; }
  .row .s-col-9-offset--left {
    lost-offset: -9/12; }
  .row .s-col-10 {
    lost-column: 10/12; }
  .row .s-flex-col-10 {
    lost-column: 10/12 flex; }
  .row .s-col-10-move--right {
    lost-move: 10/12; }
  .row .s-col-10-move--left {
    lost-move: -10/12; }
  .row .s-col-10-offset--right {
    lost-offset: 10/12; }
  .row .s-col-10-offset--left {
    lost-offset: -10/12; }
  .row .s-col-11 {
    lost-column: 11/12; }
  .row .s-flex-col-11 {
    lost-column: 11/12 flex; }
  .row .s-col-11-move--right {
    lost-move: 11/12; }
  .row .s-col-11-move--left {
    lost-move: -11/12; }
  .row .s-col-11-offset--right {
    lost-offset: 11/12; }
  .row .s-col-11-offset--left {
    lost-offset: -11/12; }
  .row .s-col-12 {
    lost-column: 12/12 0 0;
    float: none;
    width: 100%;
    max-width: 100%;
    margin: 0; }
  .row .s-flex-col-12 {
    lost-column: 12/12 0 0 flex; }
  .row .s-col-12-move--right {
    lost-move: 12/12; }
  .row .s-col-12-move--left {
    lost-move: -12/12; }
  .row .s-col-12-offset--right {
    lost-offset: 12/12; }
  .row .s-col-12-offset--left {
    lost-offset: -12/12; } }

@media only screen and (min-width: 800px) {
  .column-grid .grid-col-1 {
    lost-column: 1/12 12 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .column-grid .grid-m-col-1 {
    lost-column: 1/12 12 1rem flex; } }

@media only screen and (max-width: 640px) {
  .column-grid .grid-s-col-1 {
    lost-column: 1/12 12 1rem flex; } }

@media only screen and (min-width: 800px) {
  .column-grid .grid-col-2 {
    lost-column: 2/12 6 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .column-grid .grid-m-col-2 {
    lost-column: 2/12 6 1rem flex; } }

@media only screen and (max-width: 640px) {
  .column-grid .grid-s-col-2 {
    lost-column: 2/12 6 1rem flex; } }

@media only screen and (min-width: 800px) {
  .column-grid .grid-col-3 {
    lost-column: 3/12 4 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .column-grid .grid-m-col-3 {
    lost-column: 3/12 4 1rem flex; } }

@media only screen and (max-width: 640px) {
  .column-grid .grid-s-col-3 {
    lost-column: 3/12 4 1rem flex; } }

@media only screen and (min-width: 800px) {
  .column-grid .grid-col-4 {
    lost-column: 4/12 3 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .column-grid .grid-m-col-4 {
    lost-column: 4/12 3 1rem flex; } }

@media only screen and (max-width: 640px) {
  .column-grid .grid-s-col-4 {
    lost-column: 4/12 3 1rem flex; } }

@media only screen and (min-width: 800px) {
  .column-grid .grid-col-5 {
    lost-column: 5/12 2.4 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .column-grid .grid-m-col-5 {
    lost-column: 5/12 2.4 1rem flex; } }

@media only screen and (max-width: 640px) {
  .column-grid .grid-s-col-5 {
    lost-column: 5/12 2.4 1rem flex; } }

@media only screen and (min-width: 800px) {
  .column-grid .grid-col-6 {
    lost-column: 6/12 2 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .column-grid .grid-m-col-6 {
    lost-column: 6/12 2 1rem flex; } }

@media only screen and (max-width: 640px) {
  .column-grid .grid-s-col-6 {
    lost-column: 6/12 2 1rem flex; } }

@media only screen and (min-width: 800px) {
  .column-grid .grid-col-7 {
    lost-column: 7/12 1.71429 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .column-grid .grid-m-col-7 {
    lost-column: 7/12 1.71429 1rem flex; } }

@media only screen and (max-width: 640px) {
  .column-grid .grid-s-col-7 {
    lost-column: 7/12 1.71429 1rem flex; } }

@media only screen and (min-width: 800px) {
  .column-grid .grid-col-8 {
    lost-column: 8/12 1.5 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .column-grid .grid-m-col-8 {
    lost-column: 8/12 1.5 1rem flex; } }

@media only screen and (max-width: 640px) {
  .column-grid .grid-s-col-8 {
    lost-column: 8/12 1.5 1rem flex; } }

@media only screen and (min-width: 800px) {
  .column-grid .grid-col-9 {
    lost-column: 9/12 1.33333 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .column-grid .grid-m-col-9 {
    lost-column: 9/12 1.33333 1rem flex; } }

@media only screen and (max-width: 640px) {
  .column-grid .grid-s-col-9 {
    lost-column: 9/12 1.33333 1rem flex; } }

@media only screen and (min-width: 800px) {
  .column-grid .grid-col-10 {
    lost-column: 10/12 1.2 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .column-grid .grid-m-col-10 {
    lost-column: 10/12 1.2 1rem flex; } }

@media only screen and (max-width: 640px) {
  .column-grid .grid-s-col-10 {
    lost-column: 10/12 1.2 1rem flex; } }

@media only screen and (min-width: 800px) {
  .column-grid .grid-col-11 {
    lost-column: 11/12 1.09091 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .column-grid .grid-m-col-11 {
    lost-column: 11/12 1.09091 1rem flex; } }

@media only screen and (max-width: 640px) {
  .column-grid .grid-s-col-11 {
    lost-column: 11/12 1.09091 1rem flex; } }

@media only screen and (min-width: 800px) {
  .column-grid .grid-col-12 {
    lost-column: 12/12 1 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .column-grid .grid-m-col-12 {
    lost-column: 12/12 1 1rem flex; } }

@media only screen and (max-width: 640px) {
  .column-grid .grid-s-col-12 {
    lost-column: 12/12 1 1rem flex; } }

@media only screen and (max-width: 800px) and (min-width: 640px) {
  .hide-on-m {
    display: none !important; } }

@media only screen and (max-width: 800px) {
  .hide-below-m {
    display: none !important; } }

@media only screen and (min-width: 800px) {
  .hide-above-m {
    display: none !important; } }

@media only screen and (min-width: 640px) {
  .hide-above-s {
    display: none !important; } }

@media only screen and (max-width: 640px) {
  .hide-below-s {
    display: none !important; }
  .col-center--large,
  .col-center--medium,
  .col-center--small {
    lost-center: 100%; } }

@media only screen and (max-width: 414px) {
  .hide-below-xs {
    display: none !important; } }

.flex-grid {
  display: flex;
  /** Columns **/ }
  .flex-grid--row {
    flex-flow: row wrap; }
  .flex-grid--column {
    flex-flow: column wrap; }
  .flex-grid .col-1 {
    flex-basis: 8.31666%;
    width: 8.31666%;
    max-width: 8.31666%;
    flex: 1 1 auto; }
  @media only screen and (max-width: 800px) and (min-width: 640px) {
    .flex-grid .m-col-1 {
      flex-basis: 8.31666%;
      width: 8.31666%;
      max-width: 8.31666%;
      flex: 1 1 auto; } }
  @media only screen and (max-width: 640px) {
    .flex-grid .s-col-1 {
      flex-basis: 8.31666%;
      width: 8.31666%;
      max-width: 8.31666%;
      flex: 1 1 auto; } }
  .flex-grid .col-2 {
    flex-basis: 16.63332%;
    width: 16.63332%;
    max-width: 16.63332%;
    flex: 1 1 auto; }
  @media only screen and (max-width: 800px) and (min-width: 640px) {
    .flex-grid .m-col-2 {
      flex-basis: 16.63332%;
      width: 16.63332%;
      max-width: 16.63332%;
      flex: 1 1 auto; } }
  @media only screen and (max-width: 640px) {
    .flex-grid .s-col-2 {
      flex-basis: 16.63332%;
      width: 16.63332%;
      max-width: 16.63332%;
      flex: 1 1 auto; } }
  .flex-grid .col-3 {
    flex-basis: 24.94997%;
    width: 24.94997%;
    max-width: 24.94997%;
    flex: 1 1 auto; }
  @media only screen and (max-width: 800px) and (min-width: 640px) {
    .flex-grid .m-col-3 {
      flex-basis: 24.94997%;
      width: 24.94997%;
      max-width: 24.94997%;
      flex: 1 1 auto; } }
  @media only screen and (max-width: 640px) {
    .flex-grid .s-col-3 {
      flex-basis: 24.94997%;
      width: 24.94997%;
      max-width: 24.94997%;
      flex: 1 1 auto; } }
  .flex-grid .col-4 {
    flex-basis: 33.26663%;
    width: 33.26663%;
    max-width: 33.26663%;
    flex: 1 1 auto; }
  @media only screen and (max-width: 800px) and (min-width: 640px) {
    .flex-grid .m-col-4 {
      flex-basis: 33.26663%;
      width: 33.26663%;
      max-width: 33.26663%;
      flex: 1 1 auto; } }
  @media only screen and (max-width: 640px) {
    .flex-grid .s-col-4 {
      flex-basis: 33.26663%;
      width: 33.26663%;
      max-width: 33.26663%;
      flex: 1 1 auto; } }
  .flex-grid .col-5 {
    flex-basis: 41.58329%;
    width: 41.58329%;
    max-width: 41.58329%;
    flex: 1 1 auto; }
  @media only screen and (max-width: 800px) and (min-width: 640px) {
    .flex-grid .m-col-5 {
      flex-basis: 41.58329%;
      width: 41.58329%;
      max-width: 41.58329%;
      flex: 1 1 auto; } }
  @media only screen and (max-width: 640px) {
    .flex-grid .s-col-5 {
      flex-basis: 41.58329%;
      width: 41.58329%;
      max-width: 41.58329%;
      flex: 1 1 auto; } }
  .flex-grid .col-6 {
    flex-basis: 49.89995%;
    width: 49.89995%;
    max-width: 49.89995%;
    flex: 1 1 auto; }
  @media only screen and (max-width: 800px) and (min-width: 640px) {
    .flex-grid .m-col-6 {
      flex-basis: 49.89995%;
      width: 49.89995%;
      max-width: 49.89995%;
      flex: 1 1 auto; } }
  @media only screen and (max-width: 640px) {
    .flex-grid .s-col-6 {
      flex-basis: 49.89995%;
      width: 49.89995%;
      max-width: 49.89995%;
      flex: 1 1 auto; } }
  .flex-grid .col-7 {
    flex-basis: 58.21661%;
    width: 58.21661%;
    max-width: 58.21661%;
    flex: 1 1 auto; }
  @media only screen and (max-width: 800px) and (min-width: 640px) {
    .flex-grid .m-col-7 {
      flex-basis: 58.21661%;
      width: 58.21661%;
      max-width: 58.21661%;
      flex: 1 1 auto; } }
  @media only screen and (max-width: 640px) {
    .flex-grid .s-col-7 {
      flex-basis: 58.21661%;
      width: 58.21661%;
      max-width: 58.21661%;
      flex: 1 1 auto; } }
  .flex-grid .col-8 {
    flex-basis: 66.53327%;
    width: 66.53327%;
    max-width: 66.53327%;
    flex: 1 1 auto; }
  @media only screen and (max-width: 800px) and (min-width: 640px) {
    .flex-grid .m-col-8 {
      flex-basis: 66.53327%;
      width: 66.53327%;
      max-width: 66.53327%;
      flex: 1 1 auto; } }
  @media only screen and (max-width: 640px) {
    .flex-grid .s-col-8 {
      flex-basis: 66.53327%;
      width: 66.53327%;
      max-width: 66.53327%;
      flex: 1 1 auto; } }
  .flex-grid .col-9 {
    flex-basis: 74.84992%;
    width: 74.84992%;
    max-width: 74.84992%;
    flex: 1 1 auto; }
  @media only screen and (max-width: 800px) and (min-width: 640px) {
    .flex-grid .m-col-9 {
      flex-basis: 74.84992%;
      width: 74.84992%;
      max-width: 74.84992%;
      flex: 1 1 auto; } }
  @media only screen and (max-width: 640px) {
    .flex-grid .s-col-9 {
      flex-basis: 74.84992%;
      width: 74.84992%;
      max-width: 74.84992%;
      flex: 1 1 auto; } }
  .flex-grid .col-10 {
    flex-basis: 83.16658%;
    width: 83.16658%;
    max-width: 83.16658%;
    flex: 1 1 auto; }
  @media only screen and (max-width: 800px) and (min-width: 640px) {
    .flex-grid .m-col-10 {
      flex-basis: 83.16658%;
      width: 83.16658%;
      max-width: 83.16658%;
      flex: 1 1 auto; } }
  @media only screen and (max-width: 640px) {
    .flex-grid .s-col-10 {
      flex-basis: 83.16658%;
      width: 83.16658%;
      max-width: 83.16658%;
      flex: 1 1 auto; } }
  .flex-grid .col-11 {
    flex-basis: 91.48324%;
    width: 91.48324%;
    max-width: 91.48324%;
    flex: 1 1 auto; }
  @media only screen and (max-width: 800px) and (min-width: 640px) {
    .flex-grid .m-col-11 {
      flex-basis: 91.48324%;
      width: 91.48324%;
      max-width: 91.48324%;
      flex: 1 1 auto; } }
  @media only screen and (max-width: 640px) {
    .flex-grid .s-col-11 {
      flex-basis: 91.48324%;
      width: 91.48324%;
      max-width: 91.48324%;
      flex: 1 1 auto; } }
  .flex-grid .col-12 {
    flex-basis: 99.7999%;
    width: 99.7999%;
    max-width: 99.7999%;
    flex: 1 1 auto; }
  @media only screen and (max-width: 800px) and (min-width: 640px) {
    .flex-grid .m-col-12 {
      flex-basis: 99.7999%;
      width: 99.7999%;
      max-width: 99.7999%;
      flex: 1 1 auto;
      width: 100%;
      flex-basis: 100%;
      max-width: 100%;
      margin: 0; } }
  @media only screen and (max-width: 640px) {
    .flex-grid .s-col-12 {
      flex-basis: 99.7999%;
      width: 99.7999%;
      max-width: 99.7999%;
      flex: 1 1 auto;
      width: 100%;
      flex-basis: 100%;
      max-width: 100%;
      margin: 0; } }

.site-footer {
  background-color: #2385FC;
  overflow: hidden; }
  .site-footer__top-container {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 65px 20px 120px 20px; }
    @media only screen and (max-width: 850px) {
      .site-footer__top-container {
        flex-direction: column;
        justify-content: center; } }
    .site-footer__top-container .menu-footer-menu-container {
      max-width: 700px;
      width: 100%; }
      @media only screen and (max-width: 850px) {
        .site-footer__top-container .menu-footer-menu-container {
          margin: 0 auto;
          text-align: center; } }
      .site-footer__top-container .menu-footer-menu-container ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        list-style: none; }
        @media only screen and (max-width: 620px) {
          .site-footer__top-container .menu-footer-menu-container ul {
            flex-direction: column; } }
        .site-footer__top-container .menu-footer-menu-container ul li {
          transition: all 0.2s ease-in-out; }
          @media only screen and (max-width: 620px) {
            .site-footer__top-container .menu-footer-menu-container ul li {
              margin-bottom: 30px; } }
          .site-footer__top-container .menu-footer-menu-container ul li.current-menu-item a:after {
            transform: translateY(5px);
            opacity: 1; }
        .site-footer__top-container .menu-footer-menu-container ul li a {
          text-decoration: none;
          font-family: "Lab Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          color: #fff;
          font-size: 18px;
          line-height: 22px;
          position: relative; }
          .site-footer__top-container .menu-footer-menu-container ul li a:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: white;
            left: 0;
            bottom: 0;
            transform: translateY(12px);
            opacity: 0;
            transition: all 0.2s ease-in-out; }
          .site-footer__top-container .menu-footer-menu-container ul li a:hover:after {
            transform: translateY(5px);
            opacity: 1; }
  @media only screen and (max-width: 850px) {
    .site-footer__social-container {
      width: 100%;
      text-align: center;
      margin-top: 60px; } }
  .site-footer__social-link {
    display: inline-block;
    background: #fff;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    vertical-align: middle;
    position: relative;
    margin-bottom: 15px;
    margin-right: 8px;
    cursor: pointer;
    transition: transform .1s ease-in-out; }
    .site-footer__social-link:hover {
      transform: translateY(-3px); }
    .site-footer__social-link img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .site-footer__logo {
    width: 48px;
    height: auto; }
  .site-footer__bottom-container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px 40px 20px; }
  .site-footer__logo-container {
    display: flex;
    align-items: center;
    width: 50px;
    position: relative; }
    .site-footer__logo-container:before {
      content: '';
      display: block;
      width: 2500px;
      position: absolute;
      background: #fff;
      height: 8px;
      right: 100%;
      margin-top: 7px;
      transform: skewX(-23deg); }
    .site-footer__logo-container:after {
      content: '';
      display: block;
      width: 2500px;
      position: absolute;
      background: #fff;
      left: 100%;
      margin-left: -3px;
      height: 8px;
      margin-top: 7px;
      transform: skewX(23deg); }

.start-header {
  height: 840px;
  width: 100%;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media only screen and (max-width: 1500px) {
    .start-header {
      height: 540px; } }
  @media only screen and (max-width: 700px) {
    .start-header.has-video {
      height: 400px; } }

.start-header__inner {
  display: flex;
  align-items: center;
  vertical-align: baseline;
  position: relative; }
  .start-header__inner:before {
    content: '';
    display: block;
    width: 2500px;
    position: absolute;
    background: #fff;
    height: 16px;
    left: 100%;
    margin-top: 14px;
    transform: skewX(23deg); }
  .start-header__inner:after {
    content: '';
    display: block;
    width: 2500px;
    position: absolute;
    background: #fff;
    height: 16px;
    right: 100%;
    margin-top: 14px;
    transform: skewX(-23deg); }

.start-header-video {
  position: absolute; }
  @media only screen and (max-width: 700px) {
    .start-header-video {
      height: 400px; } }

.title-text-block {
  width: 100%;
  max-width: 1160px;
  margin: 120px auto 125px auto;
  display: flex;
  flex-direction: row;
  font-family: "Lab Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  @media only screen and (max-width: 1170px) {
    .title-text-block {
      padding: 0 20px; } }
  @media only screen and (max-width: 700px) {
    .title-text-block {
      flex-direction: column;
      margin: 60px auto; } }

.title-text-block__title {
  width: 60%;
  font-size: 72px;
  line-height: 86px;
  padding-right: 20px;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #2385FC;
  font-weight: 700; }
  @media only screen and (max-width: 700px) {
    .title-text-block__title {
      width: 100%;
      margin-bottom: 30px;
      font-size: 52px;
      line-height: 66px; } }

.title-text-block__text {
  width: 40%;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: #2385FC; }
  @media only screen and (max-width: 700px) {
    .title-text-block__text {
      width: 100%; } }

.big-image-usp {
  width: 100%;
  max-width: 1360px;
  min-height: 540px;
  margin: 125px auto 110px auto;
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 1px; }
  @media only screen and (max-width: 1360px) {
    .big-image-usp {
      width: auto;
      margin: 125px 20px 110px 20px; } }
  @media only screen and (max-width: 620px) {
    .big-image-usp {
      margin: 60px 20px; } }
  @media only screen and (max-width: 500px) {
    .big-image-usp {
      height: 366px; } }

.big-image-usp__inner {
  padding: 0 0 0 10%; }
  @media only screen and (max-width: 620px) {
    .big-image-usp__inner {
      position: absolute;
      padding: 20px;
      bottom: 0;
      top: auto;
      transform: none; } }

.big-image-usp__title {
  font-size: 56px;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 68px;
  color: #fff;
  vertical-align: baseline;
  position: relative;
  display: inline-block;
  padding: 0 30px; }
  @media only screen and (max-width: 620px) {
    .big-image-usp__title {
      padding: 0;
      font-size: 26px;
      line-height: 31px; } }
  .big-image-usp__title:before {
    content: '';
    display: block;
    width: 2500px;
    background: #fff;
    height: 8px;
    left: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media only screen and (max-width: 620px) {
      .big-image-usp__title:before {
        display: none; } }
  .big-image-usp__title:after {
    content: '';
    display: block;
    width: 2500px;
    background: #fff;
    height: 8px;
    right: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media only screen and (max-width: 620px) {
      .big-image-usp__title:after {
        display: none; } }

.big-image-usp__link {
  display: block;
  color: #fff;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  margin-top: 25px;
  padding-left: 33px;
  text-decoration: none; }
  @media only screen and (max-width: 620px) {
    .big-image-usp__link {
      padding: 0;
      margin-top: 15px; } }
  .big-image-usp__link:hover {
    text-decoration: underline; }
  .big-image-usp__link:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 11px;
    margin-right: 8px;
    background-image: url("../img/white-arrow-right.svg"); }
    @media only screen and (max-width: 620px) {
      .big-image-usp__link:before {
        display: none; } }

.text-columns {
  width: 100%;
  max-width: 1060px;
  margin: 110px auto 140px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  @media only screen and (max-width: 1100px) {
    .text-columns {
      padding: 0 20px; } }
  @media only screen and (max-width: 760px) {
    .text-columns {
      flex-direction: column;
      margin: 0 auto; } }

.text-columns__column {
  width: 50%;
  max-width: 505px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .text-columns__column .button {
    margin-top: auto; }
  @media only screen and (max-width: 760px) {
    .text-columns__column {
      width: 100%;
      max-width: none;
      margin-bottom: 60px; } }

.text-columns__title {
  font-size: 56px;
  line-height: 68px;
  color: #000;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  margin-bottom: 30px; }
  @media only screen and (max-width: 500px) {
    .text-columns__title {
      font-size: 36px;
      line-height: 43px; } }

.text-columns__text {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 35px;
  max-width: 470px;
  padding-right: 20px; }
  @media only screen and (max-width: 760px) {
    .text-columns__text {
      max-width: none; } }

.text-columns__button {
  display: inline-block;
  padding: 20px 30px;
  background: #2385FC;
  color: #fff;
  font-size: 20px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  text-align: center;
  line-height: 14px;
  margin-top: auto;
  text-decoration: none; }

.big-usp-buttons {
  width: 100%;
  max-width: 1360px;
  min-height: 540px;
  margin: 137px auto 102px auto;
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 1px; }
  @media only screen and (max-width: 620px) {
    .big-usp-buttons {
      height: auto;
      min-height: 366px;
      justify-content: center;
      margin: 50px auto; } }

.big-usp-buttons__inner {
  padding: 0 0 0 10%; }
  @media only screen and (max-width: 620px) {
    .big-usp-buttons__inner {
      padding: 20px;
      top: auto;
      transform: none; } }

.big-usp-buttons__title {
  font-size: 56px;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 68px;
  color: #fff; }
  @media only screen and (max-width: 620px) {
    .big-usp-buttons__title {
      padding: 0;
      font-size: 26px;
      line-height: 31px; } }

.big-usp-buttons__subtitle {
  display: block;
  color: #fff;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  margin: 10px 0 22px 0; }

.big-usp-buttons__holder {
  vertical-align: baseline;
  position: relative;
  display: inline-block;
  padding: 0 35px 0 0; }
  .big-usp-buttons__holder:before {
    content: '';
    display: block;
    width: 2500px;
    background: #fff;
    height: 8px;
    left: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media only screen and (max-width: 620px) {
      .big-usp-buttons__holder:before {
        display: none; } }
  .big-usp-buttons__holder:after {
    content: '';
    display: block;
    width: 2500px;
    background: #fff;
    height: 8px;
    right: 105%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media only screen and (max-width: 620px) {
      .big-usp-buttons__holder:after {
        display: none; } }
  .big-usp-buttons__holder .big-usp-buttons__button:last-of-type {
    margin-right: 0; }

.big-usp-buttons__button {
  background: #fff;
  border-radius: 100px;
  font-size: 20px;
  line-height: 26px;
  padding: 6px 20px 6px 15px;
  margin-right: 20px;
  display: inline-block;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.08);
  transition: background .1s ease-in-out; }
  .big-usp-buttons__button:hover {
    background: rgba(255, 255, 255, 0.85); }
  @media only screen and (max-width: 900px) {
    .big-usp-buttons__button {
      display: block;
      margin-bottom: 20px; } }
  .big-usp-buttons__button a {
    color: #000;
    text-decoration: none; }
    .big-usp-buttons__button a:before {
      content: '';
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background: #E2B881;
      display: inline-block;
      vertical-align: middle;
      margin: -4px 12px 0 0;
      transition: width .1s ease-in-out, height .1s ease-in-out; }

.text-list {
  width: 100%;
  max-width: 1100px;
  margin: 102px auto 115px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media only screen and (max-width: 1160px) {
    .text-list {
      padding: 0 20px; } }
  @media only screen and (max-width: 760px) {
    .text-list {
      flex-direction: column; } }

.text-list__content {
  max-width: 450px; }
  @media only screen and (max-width: 1160px) {
    .text-list__content {
      padding: 0 40px 0 0; } }
  @media only screen and (max-width: 760px) {
    .text-list__content {
      max-width: none;
      margin-bottom: 40px;
      padding: 0; } }

.text-list__text-title {
  font-size: 56px;
  line-height: 68px;
  color: #000;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-bottom: 30px; }
  @media only screen and (max-width: 500px) {
    .text-list__text-title {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 20px; } }

.text-list__text {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400; }

.text-list__list {
  width: 50%;
  max-width: 544px; }
  @media only screen and (max-width: 760px) {
    .text-list__list {
      width: 100%;
      max-width: none; } }

.text-list__list-item {
  list-style: none; }
  .text-list__list-item--last .text-list__list-link {
    color: #2385FC;
    font-weight: 400;
    font-size: 20px;
    border-bottom: 1px solid #BBBBBB;
    padding: 42px 0; }

.text-list__list-link {
  padding: 30px 0;
  border-top: 1px solid #BBBBBB;
  text-decoration: none;
  display: block;
  font-size: 20px;
  position: relative;
  padding-right: 30px; }
  .text-list__list-link:after {
    content: '';
    width: 24px;
    height: 15px;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../img/left_arrow.svg");
    transition: right 0.1s cubic-bezier(0, 0, 0.2, 1); }
  .text-list__list-link:hover:after {
    right: 10px; }

.text-list__list-subtitle {
  color: #BBBBBB;
  line-height: 24px;
  font-weight: 400; }

.text-list__list-title {
  color: #000;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

/*
*** AddSoucedPage
*/
.PageAnchorMenu {
  padding: 0 2rem; }
  @media only screen and (max-width: 640px) {
    .PageAnchorMenu {
      padding: 0 1rem; } }
  .PageAnchorMenu-wrap {
    margin: 0 auto;
    max-width: 68.75rem;
    width: 100%;
    border-bottom: solid 1px #BBBBBB;
    padding-top: 2rem;
    text-align: center; }
  .PageAnchorMenu-item {
    display: inline-block;
    padding: 0 1rem 2rem 1rem; }
  .PageAnchorMenu-link {
    color: #1E1E1E;
    font-family: "Lab Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1.125rem;
    line-height: 1.1;
    text-decoration: none; }
    .PageAnchorMenu-link:hover {
      text-decoration: underline; }

.LargeHeadingBlock {
  width: 100%;
  max-width: 800px;
  margin: 60px auto 30px auto;
  text-align: center; }
  @media only screen and (max-width: 1160px) {
    .LargeHeadingBlock {
      padding: 3rem 20px; } }
  .LargeHeadingBlock-heading {
    font-size: 3.5rem;
    line-height: 1.1;
    color: #2385FC;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    @media only screen and (max-width: 800px) {
      .LargeHeadingBlock-heading {
        font-size: 2.25rem;
        line-height: 1.1; } }

.ColumnBlocksBlock {
  padding-top: 4rem;
  margin-top: 4rem;
  border-top: solid 1px #BBBBBB;
  max-width: 68.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4rem; }
  .ColumnBlocksBlock-image {
    max-width: 100%;
    width: 100%;
    display: block; }
    .ColumnBlocksBlock-image img {
      width: inherit;
      max-width: inherit;
      display: block; }
  .ColumnBlocksBlock-column {
    align-items: center; }
  @media only screen and (max-width: 640px) {
    .ColumnBlocksBlock-image-column {
      padding-bottom: 2rem; } }
  .ColumnBlocksBlock-text-column {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: center; }
  .ColumnBlocksBlock-text-wrap {
    display: block;
    width: 100%; }
    @media only screen and (max-width: 640px) {
      .ColumnBlocksBlock-text-wrap {
        padding-bottom: 2rem; } }
    @media only screen and (min-width: 640px) {
      .ColumnBlocksBlock-text-wrap {
        padding-left: 2rem;
        padding-right: 0rem; } }
    @media only screen and (min-width: 800px) {
      .ColumnBlocksBlock-text-wrap {
        padding-left: 3rem; } }
  .ColumnBlocksBlock-subheading {
    color: #2385FC;
    font-size: 1.25rem;
    padding-bottom: 1rem; }
  .ColumnBlocksBlock-heading {
    color: #1E1E1E;
    font-size: 2.25rem;
    padding-bottom: 1rem; }
  .ColumnBlocksBlock-text {
    color: #1E1E1E;
    font-size: 1.25rem; }

.ColumnBlocksBlock-row {
  width: 100%;
  padding-bottom: 4rem; }
  @media only screen and (max-width: 640px) {
    .ColumnBlocksBlock-row {
      flex-wrap: wrap; } }
  @media only screen and (min-width: 640px) {
    .ColumnBlocksBlock-row:nth-child(even) .ColumnBlocksBlock-column:first-child {
      order: 2; } }
  @media only screen and (min-width: 640px) {
    .ColumnBlocksBlock-row:nth-child(even) .ColumnBlocksBlock-column:last-child {
      order: 1; } }
  @media only screen and (min-width: 640px) {
    .ColumnBlocksBlock-row:nth-child(even) .ColumnBlocksBlock-column:last-child .ColumnBlocksBlock-text-wrap {
      padding-left: 0rem;
      padding-right: 2rem; } }
  @media only screen and (min-width: 800px) {
    .ColumnBlocksBlock-row:nth-child(even) .ColumnBlocksBlock-column:last-child .ColumnBlocksBlock-text-wrap {
      padding-right: 3rem; } }

.TextContentCarousel {
  background-color: #2346B5;
  color: #fff;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 1rem; }
  .TextContentCarousel .flickity-viewport {
    width: 100%;
    overflow: visible; }
  @media only screen and (min-width: 800px) {
    .TextContentCarousel {
      padding-left: 2rem;
      padding-right: 2rem; } }
  .TextContentCarousel-carousel {
    font-size: 1.25rem;
    line-height: 1;
    max-width: 68.75rem;
    margin-left: auto;
    margin-right: auto; }
  .TextContentCarousel-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-right: 2rem;
    padding-bottom: 2rem;
    max-width: 68.75rem;
    margin-left: auto;
    margin-right: auto; }
  .TextContentCarousel-heading h4 {
    font-size: 1.25rem;
    line-height: 1;
    font-weight: 400;
    font-family: "Lab Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .TextContentCarousel-pagination {
    display: flex;
    flex-flow: row wrap;
    align-items: center; }
  .TextContentCarousel-arrow {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: opacity 0.2s linear;
    background: none;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; }
    .TextContentCarousel-arrow:disabled {
      opacity: 0.3;
      pointer-events: none; }
    .TextContentCarousel-arrow:focus, .TextContentCarousel-arrow:active {
      border: 0;
      outline: 0; }
    .TextContentCarousel-arrow:hover {
      background-color: rgba(255, 255, 255, 0.1); }
  .TextContentCarousel-arrow-img {
    width: 24px;
    height: 16px;
    display: block; }
    .TextContentCarousel-prev .TextContentCarousel-arrow-img {
      transform: rotate(180deg);
      transform-origin: center; }
  .TextContentCarousel-carousel {
    overflow: visible;
    display: flex;
    flex-flow: row nowrap; }
  .TextContentCarousel-column {
    width: 50%;
    border-top: solid 0.5rem #fff;
    padding: 2rem 2rem 2rem 0rem; }
    @media only screen and (max-width: 640px) {
      .TextContentCarousel-column {
        max-width: 90%; } }
    @media only screen and (min-width: 800px) {
      .TextContentCarousel-column {
        width: 33.33%;
        max-width: 33.33%; } }
    .TextContentCarousel-column-heading {
      font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: 700;
      padding-bottom: 2rem; }
    .TextContentCarousel-column-text {
      font-size: 1rem;
      line-height: 1.5; }

.InfoSection {
  background-color: #E9F2FE;
  padding: 2rem 1rem; }
  @media only screen and (min-width: 800px) {
    .InfoSection {
      padding: 5rem 2rem; } }
  .InfoSection-wrap {
    max-width: 68.75rem;
    margin-left: auto;
    margin-right: auto; }
  .InfoSection .InfoSection-image {
    padding-bottom: 3rem; }
    .InfoSection .InfoSection-image-element img {
      max-width: 100%;
      display: block;
      width: 100%; }
    @media only screen and (min-width: 640px) {
      .InfoSection .InfoSection-image-element {
        margin-left: auto; } }
    @media only screen and (max-width: 640px) {
      .InfoSection .InfoSection-image-element {
        max-width: 100%;
        display: block;
        width: 100%; } }
  .InfoSection-heading {
    font-size: 3.5rem;
    line-height: 1.1;
    color: #2346B5;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding-bottom: 3rem; }
    @media only screen and (max-width: 800px) {
      .InfoSection-heading {
        font-size: 2.25rem;
        line-height: 1.1; } }
  .InfoSection-text {
    color: #2346B5;
    font-size: 1.125rem;
    line-height: 1.44;
    padding-bottom: 3rem; }
    @media only screen and (min-width: 640px) {
      .InfoSection-text {
        margin-left: auto; } }
  @media only screen and (min-width: 640px) {
    .InfoSection-expandable-blocks {
      margin-left: auto; } }
  .InfoSection-expandable-blocks-heading {
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1.25rem;
    line-height: 1;
    padding-bottom: 2rem; }
  .InfoSection-expandable-blocks-block {
    background-color: #fff;
    margin-bottom: 1rem; }
    .InfoSection-expandable-blocks-block-header {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem 1.25rem;
      font-size: 1rem;
      cursor: pointer; }
    .InfoSection-expandable-blocks-block-content {
      padding: 1rem 1.25rem 1.5rem 1.25rem; }
      .InfoSection-expandable-blocks-block-content p {
        font-size: 1rem;
        line-height: 1.4; }

.ContactSection {
  background-color: #2346B5;
  color: #fff;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 1rem; }
  @media only screen and (min-width: 800px) {
    .ContactSection {
      padding-left: 2rem;
      padding-right: 2rem; } }
  .ContactSection-wrap {
    max-width: 68.75rem;
    margin-left: auto;
    margin-right: auto; }
  .ContactSection-heading {
    font-size: 3.5rem;
    line-height: 1.1;
    color: #fff;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding-bottom: 2rem; }
    @media only screen and (max-width: 800px) {
      .ContactSection-heading {
        font-size: 2.25rem;
        line-height: 1.1; } }
  .ContactSection-text {
    color: #fff;
    font-size: 1.125rem;
    line-height: 1.44;
    padding-bottom: 2rem; }
  .ContactSection-form .wpcf7-text::placeholder {
    color: rgba(255, 255, 255, 0.2); }
  .ContactSection-form .wpcf7-textarea {
    width: 100%;
    margin-bottom: 2rem;
    background-color: rgba(0, 0, 0, 0.1);
    border: 0;
    padding: 1rem;
    color: #fff; }
  .ContactSection-form .wpcf7-form-control-wrap label {
    font-size: 1rem; }
  .ContactSection-form .wpcf7-form-control.wpcf7-submit {
    display: inline-block;
    width: auto; }
  .ContactSection-form .contact-form-plugin__columns .ajax-loader {
    display: none; }
    .ContactSection-form .contact-form-plugin__columns .ajax-loader.is-active {
      display: block; }

.MultipleHeadings {
  text-align: center;
  width: 100%;
  padding: 5rem 1rem; }
  .MultipleHeadings-small-heading {
    color: #2385FC;
    font-size: 1.25rem;
    padding-bottom: 2rem; }
  .MultipleHeadings-headings {
    max-width: 800px;
    margin: auto; }
  .MultipleHeadings-large-heading {
    font-size: 2.25rem;
    color: #1E1E1E; }
    .MultipleHeadings-large-heading:not(:last-child) {
      padding-bottom: 1.5rem; }

.ColumnsInRows {
  max-width: 68.75rem;
  margin-left: auto;
  margin-right: auto;
  padding: 4rem 1rem;
  text-align: center; }
  .ColumnsInRows-small-heading {
    color: #2385FC;
    font-size: 1.25rem;
    padding-bottom: 5rem;
    margin: auto; }
  .ColumnsInRows-grid {
    justify-content: center; }
  .ColumnsInRows-column {
    text-align: center;
    position: relative;
    flex: auto !important;
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
    padding: 0 2rem 2rem 2rem; }
    @media only screen and (min-width: 800px) {
      .ColumnsInRows-column {
        padding: 0 3rem 3rem 3rem; } }
    .ColumnsInRows-column-link {
      display: block;
      text-decoration: none; }
    .ColumnsInRows-column-image {
      width: 100%;
      max-width: 100%;
      display: block;
      padding-bottom: 2rem; }
      .ColumnsInRows-column-image img {
        width: 100%;
        max-width: 100%;
        display: block; }
    .ColumnsInRows-column-heading {
      color: #2385FC;
      font-size: 1.25rem;
      padding-bottom: 1rem; }
    .ColumnsInRows-column-text {
      font-size: 1rem;
      color: #1E1E1E; }

.page-template-page-addsourced .SimplePageHeader {
  margin-bottom: 0; }
  @media only screen and (max-width: 640px) {
    .page-template-page-addsourced .SimplePageHeader__heading {
      font-size: 3rem; } }
  @media only screen and (max-width: 800px) {
    .page-template-page-addsourced .SimplePageHeader__heading {
      font-size: 4rem; } }
  @media only screen and (min-width: 800.01px) {
    .page-template-page-addsourced .SimplePageHeader__heading {
      font-size: 5rem; }
      .page-template-page-addsourced .SimplePageHeader__heading--has-form {
        font-size: 4rem; } }

.addsource-page-section {
  overflow-x: hidden; }
  .addsource-page-section .ContentWrap {
    padding-left: 1rem;
    padding-right: 1rem; }
    @media only screen and (min-width: 640px) {
      .addsource-page-section .ContentWrap {
        padding-left: 2rem;
        padding-right: 2rem; } }

/*
*** AddSoucedPage
*/
.package-block:last-of-type {
  margin-bottom: 100px; }

@media only screen and (max-width: 1160px) {
  .package-block {
    padding: 0 20px; } }

.package-block_inner {
  width: 100%;
  max-width: 68.75rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #BBBBBB; }
  @media only screen and (max-width: 760px) {
    .package-block_inner {
      flex-direction: column; } }

.package-block__big-title {
  font-size: 56px;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 0 0 50px 0;
  max-width: 1100px;
  margin: 0 auto;
  border-bottom: 1px solid #BBBBBB; }
  @media only screen and (max-width: 1160px) {
    .package-block__big-title {
      margin: 0 20px; } }
  @media only screen and (max-width: 500px) {
    .package-block__big-title {
      font-size: 36px;
      line-height: 43px; } }

.package-block__content {
  width: 50%;
  max-width: 450px;
  padding: 0 20px 0 0; }
  @media only screen and (max-width: 760px) {
    .package-block__content {
      width: 100%;
      max-width: none;
      padding: 0; } }
  @media only screen and (max-width: 500px) {
    .package-block__content .package-block__subtitle {
      margin-top: 20px; } }

.package-block__subtitle {
  font-size: 20px;
  line-height: 24px;
  color: #575757;
  margin: 47px 0;
  display: block; }

.package-block__title {
  font-size: 36px;
  line-height: 43px;
  color: #000;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-bottom: 20px; }

.package-block__text {
  font-size: 20px;
  line-height: 26px;
  color: #575757; }

.package-block__list-holder {
  width: 50%;
  max-width: 545px; }
  @media only screen and (max-width: 760px) {
    .package-block__list-holder {
      width: 100%;
      max-width: none; } }

.package-block__list {
  width: 100%; }

.package-block__list-item {
  list-style: none; }

.package-block__list-link {
  font-size: 20px;
  list-style: 24px;
  color: #2385FC;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 42px 0;
  display: block;
  text-decoration: none;
  border-top: 1px solid #BBBBBB;
  position: relative;
  padding-right: 30px; }
  .package-block__list-link:after {
    content: '';
    width: 24px;
    height: 15px;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../img/left_arrow.svg");
    transition: right 0.1s cubic-bezier(0, 0, 0.2, 1); }
  .package-block__list-link:hover:after {
    right: 10px; }

.small-text-block {
  width: 100%;
  max-width: 68.75rem;
  margin: 85px auto 60px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 1160px) {
    .small-text-block {
      padding: 0 20px; } }
  @media only screen and (max-width: 620px) {
    .small-text-block {
      flex-direction: column; } }

.small-text-block__content {
  width: 50%;
  max-width: 585px;
  padding: 0 30px 0 0; }
  @media only screen and (max-width: 620px) {
    .small-text-block__content {
      padding: 0;
      width: 100%;
      max-width: none;
      margin: 0 0 40px 0; } }

.small-text-block__title {
  font-size: 36px;
  line-height: 46px;
  color: #000;
  margin: 0 0 20px 0;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.small-text-block__text {
  font-size: 20px;
  line-height: 26px;
  color: #575757; }
  .small-text-block__text p {
    margin: 20px 0; }

.small-text-block__quote {
  width: 50%;
  max-width: 450px;
  color: #2385FC;
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0; }
  @media only screen and (max-width: 620px) {
    .small-text-block__quote {
      width: 100%;
      max-width: none; } }

.small-text-block__author {
  font-size: 20px;
  line-height: 26px;
  color: #575757;
  display: block;
  margin-top: 10px;
  font-weight: 400; }

.accordion {
  width: 100%;
  max-width: 1100px;
  margin: 40px auto 60px auto; }
  @media only screen and (max-width: 1160px) {
    .accordion {
      padding: 0 20px; } }
  .accordion .accordion__item:last-of-type {
    border-bottom: 1px solid #BBBBBB; }

.accordion__item {
  width: 100%;
  max-width: 710px;
  border-top: 1px solid #BBBBBB; }
  .accordion__item.is-open .accordion__text {
    overflow: auto;
    height: auto;
    margin: 0 0 50px 0; }
  .accordion__item.is-open .accordion__header:after {
    transform: rotate(180deg); }

.accordion__header {
  padding: 30px 0;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; }
  .accordion__header span {
    max-width: 80%; }
  .accordion__header:after {
    content: '';
    width: 29px;
    height: 16px;
    display: inline-block;
    margin-right: 20px;
    background-image: url("../img/bluedownarrow.svg");
    transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
    will-change: transform; }

.accordion__text {
  font-size: 20px;
  line-height: 26px;
  color: #575757;
  height: 0;
  max-width: 535px;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1); }

.related-block {
  width: 100%;
  max-width: 68.75rem;
  margin: 90px auto 70px auto; }
  @media only screen and (max-width: 1160px) {
    .related-block {
      padding: 0 20px; } }

.related-block__title {
  font-size: 28px;
  line-height: 38px;
  color: #2385FC;
  padding-bottom: 45px; }

.related-block__inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  .related-block__inner .related-block__item:nth-child(n+3) {
    border-top: none; }
  @media only screen and (max-width: 620px) {
    .related-block__inner {
      flex-direction: column; }
      .related-block__inner .related-block__item:nth-child(n+2) {
        border-top: none; } }

.related-block__item {
  font-size: 20px;
  line-height: 24px;
  width: 50%;
  max-width: 500px;
  color: #2385FC;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 30px 0;
  text-decoration: none;
  border-top: 1px solid #BBBBBB;
  border-bottom: 1px solid #BBBBBB;
  position: relative; }
  .related-block__item-inner {
    display: flex;
    flex-direction: column;
    max-width: 90%; }
  @media only screen and (max-width: 1160px) {
    .related-block__item {
      width: 48%; } }
  @media only screen and (max-width: 620px) {
    .related-block__item {
      width: 100%;
      max-width: none; } }
  .related-block__item:after {
    content: '';
    width: 24px;
    height: 15px;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../img/left_arrow.svg");
    transition: right 0.1s cubic-bezier(0, 0, 0.2, 1); }
  .related-block__item:hover:after {
    right: 10px; }

.related-block__item-subtitle {
  font-size: 20px;
  line-height: 24px;
  color: #BBBBBB;
  display: block;
  font-weight: 400; }

.related-block__item-title {
  font-size: 20px;
  line-height: 24px;
  color: #000;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  max-width: 90%; }

.bottom-menu {
  width: 100%;
  max-width: 68.75rem;
  margin: 70px auto 0 auto;
  padding: 20px 10px;
  background: #fff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row; }
  @media only screen and (max-width: 760px) {
    .bottom-menu {
      display: none; } }

.bottom-menu__column {
  width: calc(100% / 3); }

.bottom-menu__current-link {
  text-align: center;
  color: #2385FC;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  display: block;
  text-decoration: none; }

.bottom-menu__next-link {
  text-align: center;
  color: #BBBBBB;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  display: block;
  text-align: right;
  text-decoration: none;
  transition: color .1s ease; }
  .bottom-menu__next-link:hover {
    color: #2385FC; }
    .bottom-menu__next-link:hover:after {
      background-image: url("../img/left_arrow.svg"); }
  .bottom-menu__next-link:after {
    content: '';
    display: inline-block;
    width: 18px;
    height: 11px;
    margin-left: 13px;
    background-size: cover;
    background-image: url("../img/grayrightarrow.svg"); }

.bottom-menu__archive-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none; }
  .bottom-menu__archive-link:hover .bottom-menu__hamburger-line:before {
    top: -4px; }
  .bottom-menu__archive-link:hover .bottom-menu__hamburger-line:after {
    top: 4px; }

.bottom-menu__hamburger {
  width: 23px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 13px; }

.bottom-menu__hamburger-text {
  color: #2385FC;
  font-size: 18px;
  line-height: 22px; }

.bottom-menu__hamburger-line {
  display: block;
  position: relative;
  width: 100%;
  height: 1.5px;
  background: #2385FC; }
  .bottom-menu__hamburger-line:before, .bottom-menu__hamburger-line:after {
    content: '';
    width: 100%;
    position: absolute;
    height: 1.5px;
    background: #2385FC;
    transition: top .1s ease-in-out; }
  .bottom-menu__hamburger-line:before {
    top: -7px; }
  .bottom-menu__hamburger-line:after {
    top: 7px; }

.case-references-page-content .layout-case_link_block:first-of-type {
  margin-top: -4.375rem;
  /* -70/16 */ }

.case-column {
  width: 100%;
  max-width: 68.75rem;
  margin: 0 auto 50px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3.125rem;
  /* 50/16 */ }
  @media only screen and (max-width: 68.75rem) {
    .case-column {
      padding: 0 20px; } }
  @media only screen and (max-width: 640px) {
    .case-column {
      flex-direction: column; } }

.case-column--image-right .case-column__image-column {
  order: 2;
  margin-left: auto;
  margin-right: 0; }
  @media only screen and (max-width: 640px) {
    .case-column--image-right .case-column__image-column {
      order: 1;
      margin-left: 0; } }

.case-column__image {
  max-width: 100%;
  width: 100%;
  display: block; }
  .case-column__image img {
    width: 100%;
    max-width: inherit;
    display: block; }

.case-column__content-column {
  padding-left: 2rem; }
  .case-column--image-right .case-column__content-column {
    order: 1;
    margin-left: 0;
    padding-left: 0;
    padding-right: 2rem; }
    @media only screen and (max-width: 640px) {
      .case-column--image-right .case-column__content-column {
        order: 2; } }
    .case-column--image-right .case-column__content-column .case-column__content {
      margin-left: 0;
      margin-right: auto; }
  @media only screen and (max-width: 640px) {
    .case-column__content-column {
      padding-left: 0;
      padding-right: 0; } }

.case-column__content {
  padding: 4rem 0;
  max-width: 460px;
  margin-left: auto; }
  @media only screen and (max-width: 640px) {
    .case-column__content {
      padding: 2rem 0 1rem; } }

.case-column__subtitle {
  font-size: 20px;
  line-height: 24px;
  color: #BBBBBB;
  display: block;
  margin: 0 0 30px 0; }

.case-column__title {
  font-size: 36px;
  line-height: 43px;
  color: #000;
  display: block;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0 0 30px 0; }

.case-column__text {
  font-size: 20px;
  line-height: 24px;
  color: #575757;
  display: block;
  margin: 0 0 30px 0; }

.case-column__link {
  display: block;
  text-decoration: none;
  color: #2385FC;
  font-size: 20px;
  position: relative; }
  .case-column__link:after {
    content: '';
    width: 17px;
    height: 11px;
    margin-left: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../img/left_arrow.svg");
    transition: margin-left 0.1s cubic-bezier(0, 0, 0.2, 1);
    background-size: cover; }
  .case-column__link:hover:after {
    margin-left: 12px; }

.center-image-quote {
  width: 100%;
  max-width: 915px;
  margin: 147px auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  @media only screen and (max-width: 68.75rem) {
    .center-image-quote {
      padding: 0 1rem; } }
  @media only screen and (max-width: 800px) {
    .center-image-quote {
      margin: 55px auto; } }
  .center-image-quote__image-holder {
    text-align: center; }
  .center-image-quote__image {
    margin: auto;
    border-radius: 100%; }
    @media only screen and (max-width: 640px) {
      .center-image-quote__image {
        margin-bottom: 2rem; } }
  .center-image-quote__content {
    margin-left: 0;
    max-width: 670px;
    margin-left: auto;
    padding-left: 30px; }
    .center-image-quote__content--no-image {
      max-width: 670px;
      margin: auto;
      padding-left: 0; }
  .center-image-quote__text {
    color: #2385FC;
    font-size: 36px;
    line-height: 1.2;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin: 0 0 10px 0; }
    @media only screen and (max-width: 640px) {
      .center-image-quote__text {
        padding: 0 1rem;
        -ms-word-break: normal;
        word-break: normal;
        /* Non standard for WebKit */
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto; } }
  .center-image-quote__author {
    font-size: 20px;
    line-height: 2.1;
    color: #575757;
    display: block; }

.case-top-header {
  width: 100%;
  height: 544px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #2385FC; }

.case-top-header__inner {
  width: 100%;
  max-width: 68.75rem;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 640px) {
    .case-top-header__inner {
      flex-direction: column; } }

.case-top-header__title {
  font-size: 56px;
  color: #fff;
  line-height: 68px;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  @media only screen and (max-width: 960px) {
    .case-top-header__title {
      font-size: 36px;
      line-height: 42px; } }

.case-top-header__logo {
  position: relative;
  display: flex;
  align-items: center;
  width: 30%; }
  .case-top-header__logo:before {
    content: '';
    display: inline-block;
    width: 124px;
    position: relative;
    background: #fff;
    height: 8px;
    margin: 14px 20px 0 30px; }
  .case-top-header__logo:after {
    content: '';
    display: inline-block;
    width: 124px;
    position: relative;
    background: #fff;
    height: 8px;
    margin: 14px 30px 0 20px; }
  .case-top-header__logo img {
    max-width: 50px; }
  @media only screen and (max-width: 640px) {
    .case-top-header__logo {
      width: 100%;
      justify-content: center;
      order: 3;
      margin-top: 60px; }
      .case-top-header__logo:after {
        width: 100%;
        margin: 14px 0 0 20px; }
      .case-top-header__logo:before {
        width: 100%;
        margin: 14px 20px 0 0; } }

.image-center-text-button {
  width: 100%;
  min-height: 540px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  color: #fff;
  margin-top: 100px;
  background-color: #2385FC;
  height: 1px; }
  .image-center-text-button--no-image {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' x='0px' y='0px' viewBox='0 0 1916.3 1114.5' enable-background='new 0 0 1916.3 1114.5'%3E\a%3Cpath fill='%232D78FF' d='M0.4-3.1v1117.7L844.4-2.2C844.4-2.2,0.5-3.3,0.4-3.1z'/%3E\a%3Cpolygon fill='%232A83FF' points='844.4,-2.2 0.4,1114.6 1181.6,1114.6 '/%3E\a%3Cpolygon fill='%232D94FF' points='844.4,-2.2 1916.2,-2.2 1081.9,784.3 '/%3E\a%3Cpolygon fill='%2326A3FF' points='1181.6,1114.6 1081.8,784.4 1916.2,-2.2 1916.2,1114.7 '/%3E\a%3C/svg%3E"); }
  @media only screen and (max-width: 500px) {
    .image-center-text-button {
      min-height: 444px; } }

.image-center-text-button__inner {
  margin: 0 auto;
  text-align: center; }

.image-center-text-button__title {
  font-size: 3.5rem;
  line-height: 68px;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  @media only screen and (max-width: 500px) {
    .image-center-text-button__title {
      font-size: 2.25rem;
      line-height: 42px; } }

.image-center-text-button__text {
  font-size: 1.25rem;
  line-height: 26px;
  color: #fff;
  margin-bottom: 30px; }

.blue-ingress-block {
  width: 100%;
  max-width: 68.75rem;
  margin: 56px auto 46px auto; }
  @media only screen and (max-width: 1160px) {
    .blue-ingress-block {
      padding: 0 20px; } }
  .blue-ingress-block h4 {
    font-size: 1.75rem;
    line-height: 32px;
    color: #2385FC;
    max-width: 660px; }

.large-heading-block {
  width: 100%;
  max-width: 68.75rem;
  margin: 60px auto 30px auto; }
  @media only screen and (max-width: 1160px) {
    .large-heading-block {
      padding: 0 20px; } }
  .large-heading-block h2 {
    font-size: 3.5rem;
    line-height: 68px;
    color: #000;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    @media only screen and (max-width: 620px) {
      .large-heading-block h2 {
        font-size: 30px;
        line-height: 38px; } }

.case-flow-columns {
  width: 100%;
  max-width: 68.75rem;
  margin: 80px auto 100px auto; }

.case-flow-columns__inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .case-flow-columns__inner .case-flow-columns__column:last-of-type:after {
    width: 0; }

.case-flow-columns__column {
  width: calc(100% / 4);
  padding: 0 7px;
  display: flex;
  position: relative; }
  @media only screen and (max-width: 800px) {
    .case-flow-columns__column {
      width: calc(100% / 2);
      padding: 10px; }
      .case-flow-columns__column:nth-child(even):after {
        width: 0; } }
  @media only screen and (max-width: 500px) {
    .case-flow-columns__column {
      width: 100%; }
      .case-flow-columns__column:nth-child(even):after {
        width: 5px; } }
  .case-flow-columns__column:after {
    content: '';
    width: 100%;
    display: block;
    height: 5px;
    background: #2385FC;
    z-index: -1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media only screen and (max-width: 500px) {
      .case-flow-columns__column:after {
        width: 5px;
        height: 100%;
        transform: none;
        margin: 0 auto;
        left: 0;
        right: 0; } }

.case-flow-columns__column-inner {
  background: #FFFFFF;
  padding: 94px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.06); }
  @media only screen and (max-width: 500px) {
    .case-flow-columns__column-inner {
      padding: 70px 20px; } }
  .case-flow-columns__column-inner p {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.22px; }

.large-block-quote {
  width: 100%;
  max-width: 1360px;
  background: rgba(35, 133, 252, 0.1);
  margin: 72px auto;
  padding: 178px 20px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column; }
  @media only screen and (max-width: 760px) {
    .large-block-quote {
      padding: 48px 20px; } }
  .large-block-quote blockquote {
    font-size: 2.25rem;
    line-height: 42px;
    color: #2385FC;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    max-width: 920px;
    width: 100%; }
    @media only screen and (max-width: 620px) {
      .large-block-quote blockquote {
        font-size: 1.625rem;
        line-height: 32px; } }
  .large-block-quote p {
    font-size: 1.25rem;
    color: #575757;
    margin-top: 20px; }

.text-heading-block {
  width: 100%;
  max-width: 68.75rem;
  margin: 0 auto; }
  @media only screen and (max-width: 1160px) {
    .text-heading-block {
      padding: 0 20px; } }
  .text-heading-block h3 {
    font-size: 2.25rem;
    line-height: 43px;
    letter-spacing: 0.43px;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 20px;
    max-width: 585px; }
    @media only screen and (max-width: 500px) {
      .text-heading-block h3 {
        font-size: 22px;
        line-height: 28px; } }
  .text-heading-block__content {
    width: 60%;
    max-width: 585px;
    color: #575757;
    line-height: 26px;
    margin: 30px 0 56px 0;
    font-size: 1.25rem; }
    .text-heading-block__content ul {
      padding: 0 40px; }
    .text-heading-block__content p {
      margin: 20px 0; }
    .text-heading-block__content a {
      color: #2385FC; }
    @media only screen and (max-width: 500px) {
      .text-heading-block__content {
        width: 100%; } }

.image-link-list {
  width: 100%;
  max-width: 1100px;
  margin: 113px auto; }
  @media only screen and (max-width: 1160px) {
    .image-link-list {
      padding: 0 20px; } }
  .image-link-list__title {
    font-size: 1.25rem;
    line-height: 26px;
    color: #BBBBBB;
    margin-bottom: 55px;
    display: block; }
    @media only screen and (max-width: 760px) {
      .image-link-list__title {
        margin-bottom: 40px; } }
  .image-link-list__featured-post {
    position: relative; }
    @media only screen and (max-width: 800px) {
      .image-link-list__featured-post {
        padding-bottom: 2rem; } }
  .image-link-list__featured-post-link {
    display: block;
    width: 100%; }
  .image-link-list__featured-post-image {
    display: block;
    width: 100%;
    height: 0;
    position: relative;
    background: #2385FC;
    overflow: hidden; }
    .image-link-list__featured-post-image-element {
      position: absolute; }
    @media only screen and (max-width: 800px) {
      .image-link-list__featured-post-image {
        width: 100%; }
        .image-link-list__featured-post-image-element {
          width: 100%; } }
  .image-link-list__featured-post-heading {
    font-size: 2.25rem;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    padding-left: 1rem;
    padding-bottom: 1rem; }
  .image-link-list .text-list__list {
    margin-left: auto; }
    @media only screen and (min-width: 68.75rem) {
      .image-link-list .text-list__list {
        max-width: 450px; } }
    @media only screen and (max-width: 68.75rem) {
      .image-link-list .text-list__list {
        padding: 0 0 0 20px; } }
    @media only screen and (max-width: 800px) {
      .image-link-list .text-list__list {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        padding: 0; } }
    .image-link-list .text-list__list-item:last-of-type {
      border-bottom: 1px solid #BBBBBB; }

.contact-persons {
  width: 100%;
  max-width: 1360px;
  margin: 90px auto;
  background: rgba(35, 133, 252, 0.1);
  padding: 95px 20px 165px 20px; }
  @media only screen and (max-width: 414px) {
    .contact-persons {
      padding: 40px 20px;
      margin: 60px auto; } }
  .contact-persons__heading {
    font-size: 3.5rem;
    line-height: 68px;
    color: #2385FC;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-align: center;
    margin-bottom: 88px; }
    @media only screen and (max-width: 414px) {
      .contact-persons__heading {
        font-size: 2.25rem;
        line-height: 42px;
        margin-bottom: 50px; } }
  .contact-persons__grid {
    width: 100%;
    margin: 0 auto;
    max-width: 68.75rem;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center; }
    .contact-persons__grid-card {
      width: calc(100% / 6);
      padding: 0 6px; }
      @media only screen and (max-width: 870px) {
        .contact-persons__grid-card {
          width: calc(100% / 4);
          padding: 15px 6px; } }
      @media only screen and (max-width: 500px) {
        .contact-persons__grid-card {
          width: calc(100% / 2); } }
      @media only screen and (max-width: 414px) {
        .contact-persons__grid-card {
          width: 100%; } }
      .contact-persons__grid-card img {
        max-width: 100%;
        display: block; }
      .contact-persons__grid-card span {
        display: block; }
      .contact-persons__grid-card a {
        display: block; }
  .contact-persons__name {
    color: #2385FC;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    line-height: 19px;
    margin: 20px 0 12px 0; }
  .contact-persons__title, .contact-persons__phone {
    color: #000;
    margin-bottom: 10px;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-decoration: none;
    font-size: 14px;
    line-height: 17px; }
  .contact-persons__mail {
    color: #2385FC;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline; }

.big-image-grid {
  width: 100%;
  max-width: 1114px;
  margin: 114px auto; }
  .big-image-grid__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .big-image-grid__title {
    font-size: 1.25rem;
    line-height: 26px;
    color: #BBBBBB;
    margin-bottom: 55px;
    display: block;
    padding-left: 7px; }
    @media only screen and (max-width: 760px) {
      .big-image-grid__title {
        margin-bottom: 40px;
        padding-left: 20px; } }
  .big-image-grid__card-holder {
    margin-bottom: 1rem; }
  .big-image-grid__card-image {
    position: relative;
    background-color: #2385FC;
    display: block;
    height: auto;
    width: 100%;
    max-width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 103%; }
    @media only screen and (max-width: 415px) {
      .big-image-grid__card-image {
        padding-bottom: 100%; } }
    .big-image-grid__card-image-element {
      position: absolute;
      display: block;
      width: 100%;
      max-width: 100%; }
  .big-image-grid__card {
    width: 100%;
    height: 100%;
    display: block;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    transition: opacity 0.8s linear; }
    .big-image-grid__card-tag {
      padding: 0.625rem 1.25rem 0.625rem 1.25rem;
      /* 10/16 */
      /* 10/16 */
      background: #fff;
      border-radius: 50px;
      color: #000;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      position: relative;
      margin: 10px 0 0 10px;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0; }
      .big-image-grid__card-tag-text {
        display: inline-block; }
        .big-image-grid__card-tag-text:before {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: #2385FC;
          display: inline-block;
          margin-right: 1rem; }
    .big-image-grid__card-title {
      font-size: 2.25rem;
      line-height: 43px;
      color: #fff;
      position: absolute;
      bottom: 40px;
      left: 40px;
      width: 100%;
      max-width: 410px;
      z-index: 1;
      font-weight: bold;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 1rem; }
      @media only screen and (max-width: 800px) {
        .big-image-grid__card-title {
          font-size: 26px;
          line-height: 1.19;
          bottom: 20px;
          left: 20px;
          width: 80%; } }
    .big-image-grid__card__overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 0;
      background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.01) 0%, #000 130%);
      transition: opacity 0.4s linear; }
      .big-image-grid__card:hover .big-image-grid__card__overlay {
        opacity: 1; }

.career-link-block {
  width: 100%;
  max-width: 1100px;
  margin: 108px auto; }
  @media only screen and (max-width: 1160px) {
    .career-link-block {
      padding: 0 20px;
      margin: 60px auto; } }
  .career-link-block__header {
    font-size: 3.5rem;
    line-height: 68px;
    color: #000;
    font-weight: bold;
    margin-bottom: 30px; }
    @media only screen and (max-width: 500px) {
      .career-link-block__header {
        font-size: 2.25rem;
        line-height: 42px;
        margin-bottom: 10px; } }
  .career-link-block__inner {
    width: 100%;
    display: flex;
    flex-direction: row; }
    @media only screen and (max-width: 770px) {
      .career-link-block__inner {
        flex-direction: column; } }
  .career-link-block__text {
    width: 50%;
    max-width: 450px;
    font-size: 1.25rem;
    color: #575757;
    line-height: 26px; }
    .career-link-block__text p {
      margin: 20px 0; }
      .career-link-block__text p a {
        color: #2385FC; }
    @media only screen and (max-width: 1160px) {
      .career-link-block__text {
        padding: 0 40px 0 0; } }
    @media only screen and (max-width: 770px) {
      .career-link-block__text {
        width: 100%;
        max-width: none; } }
  .career-link-block__preamble {
    font-size: 20px;
    line-height: 26px;
    color: #575757;
    margin-bottom: 20px; }
  .career-link-block__sidebar-text {
    font-size: 20px;
    line-height: 26px;
    color: #575757;
    margin-top: 20px; }
    .career-link-block__sidebar-text a {
      color: #2385FC; }
  .career-link-block__list-holder {
    width: 50%;
    max-width: 540px;
    margin-left: auto; }
    @media only screen and (max-width: 770px) {
      .career-link-block__list-holder {
        width: 100%;
        max-width: none;
        margin-left: 0;
        margin-top: 40px; } }
  .career-link-block__list {
    width: 100%; }
    .career-link-block__list-item {
      width: 100%;
      list-style: none;
      font-size: 20px;
      line-height: 26px;
      color: #575757;
      border-bottom: 1px solid #BBBBBB; }
      .career-link-block__list-item:first-of-type {
        border-top: 1px solid #BBBBBB; }
    .career-link-block__list-link {
      text-decoration: none;
      display: block;
      position: relative;
      padding-right: 30px;
      color: #000;
      padding: 40px 0;
      font-weight: bold; }
      .career-link-block__list-link span {
        max-width: 80%; }
      .career-link-block__list-link:after {
        content: '';
        width: 24px;
        height: 15px;
        right: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("../img/left_arrow.svg");
        transition: right 0.1s cubic-bezier(0, 0, 0.2, 1); }
      .career-link-block__list-link:hover:after {
        right: 10px; }

.NextCaseTeaser {
  text-align: center; }
  .NextCaseTeaser a {
    text-decoration: none; }
  .NextCaseTeaser__no-background-image {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' x='0px' y='0px' viewBox='0 0 1916.3 1114.5' enable-background='new 0 0 1916.3 1114.5'%3E\a%3Cpath fill='%232D78FF' d='M0.4-3.1v1117.7L844.4-2.2C844.4-2.2,0.5-3.3,0.4-3.1z'/%3E\a%3Cpolygon fill='%232A83FF' points='844.4,-2.2 0.4,1114.6 1181.6,1114.6 '/%3E\a%3Cpolygon fill='%232D94FF' points='844.4,-2.2 1916.2,-2.2 1081.9,784.3 '/%3E\a%3Cpolygon fill='%2326A3FF' points='1181.6,1114.6 1081.8,784.4 1916.2,-2.2 1916.2,1114.7 '/%3E\a%3C/svg%3E"); }
  .NextCaseTeaser__holder {
    color: #fff;
    background-color: #2385FC;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    min-height: 444px;
    overflow: hidden;
    position: relative; }
  .NextCaseTeaser__wrap {
    max-width: 68.75rem;
    width: 100%;
    margin: auto;
    padding: 3rem 0;
    /* 120/16 */ }
    @media only screen and (max-width: 68.75rem) {
      .NextCaseTeaser__wrap {
        margin: 3rem 0;
        padding: 0 1.25rem; } }
    @media only screen and (max-width: 640px) {
      .NextCaseTeaser__wrap {
        padding-bottom: 3rem;
        display: flex;
        align-items: center; } }
  .NextCaseTeaser__heading {
    color: inherit;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 3.5rem;
    line-height: 1.2;
    width: auto;
    display: inline-block;
    position: relative; }
    @media only screen and (max-width: 640px) {
      .NextCaseTeaser__heading {
        font-size: 2.25rem; } }
    .NextCaseTeaser__heading:before {
      content: "";
      margin-left: 2rem;
      display: block;
      width: 2500px;
      background: #fff;
      height: 8px;
      left: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
      @media only screen and (max-width: 640px) {
        .NextCaseTeaser__heading:before {
          display: none; } }
    .NextCaseTeaser__heading:after {
      content: "";
      margin-right: 2rem;
      display: block;
      width: 2500px;
      background: #fff;
      height: 8px;
      right: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
      @media only screen and (max-width: 640px) {
        .NextCaseTeaser__heading:after {
          display: none; } }
  .NextCaseTeaser__link-button {
    display: inline-block;
    position: relative;
    transform: translateY(50%);
    margin: auto;
    z-index: 99; }
  .NextCaseTeaser__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.4) 0%, #fff 100%);
    transition: opacity 0.8s linear; }
    .NextCaseTeaser:hover .NextCaseTeaser__overlay {
      opacity: 0; }

.partner-block {
  width: 100%;
  max-width: 1100px;
  margin: 87px auto; }
  @media only screen and (max-width: 1160px) {
    .partner-block {
      padding: 0 20px; } }
  @media only screen and (max-width: 500px) {
    .partner-block {
      margin: 60px auto 100px auto; } }
  .partner-block__heading {
    font-size: 3.5rem;
    line-height: 68px;
    color: #000;
    margin-bottom: 60px;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    @media only screen and (max-width: 500px) {
      .partner-block__heading {
        font-size: 2.25rem;
        line-height: 43px;
        margin-bottom: 40px; } }
  .partner-block__partner {
    width: 100%;
    max-width: 730px;
    margin-bottom: 60px; }
    .partner-block__partner-logo {
      margin-bottom: 30px;
      display: block; }
    .partner-block__partner-name {
      font-size: 2.25rem;
      line-height: 43px;
      color: #000;
      font-weight: bold;
      margin-bottom: 20px; }
      @media only screen and (max-width: 500px) {
        .partner-block__partner-name {
          font-size: 26px;
          line-height: 32px; } }
    .partner-block__partner-desc {
      font-size: 20px;
      line-height: 26px;
      color: #575757; }

.text-heading-highlight {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 50px auto; }
  @media only screen and (max-width: 1160px) {
    .text-heading-highlight {
      padding: 0 20px; } }
  @media only screen and (max-width: 770px) {
    .text-heading-highlight {
      flex-direction: column; } }
  .text-heading-highlight__text {
    width: 50%;
    max-width: 635px;
    padding: 0 20px 0 0; }
    @media only screen and (max-width: 770px) {
      .text-heading-highlight__text {
        width: 100%;
        max-width: none; } }
    .text-heading-highlight__text h3 {
      font-size: 36px;
      line-height: 43px;
      font-weight: 700;
      font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      margin-bottom: 30px; }
      @media only screen and (max-width: 500px) {
        .text-heading-highlight__text h3 {
          font-size: 22px;
          line-height: 28px; } }
    .text-heading-highlight__text p {
      font-size: 20px;
      line-height: 26px;
      color: #575757; }
    .text-heading-highlight__text a {
      color: #2385FC; }
  .text-heading-highlight__highlight {
    width: 50%;
    max-width: 370px;
    margin-left: auto;
    background: rgba(35, 133, 252, 0.1);
    padding: 20px;
    text-align: center; }
    @media only screen and (max-width: 770px) {
      .text-heading-highlight__highlight {
        width: 100%;
        margin: 60px auto; } }
    .text-heading-highlight__highlight p {
      color: #2385FC;
      font-size: 26px;
      line-height: 32px;
      font-weight: 700;
      font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.article-header {
  width: 100%;
  max-width: 1100px;
  margin: 120px auto 40px auto; }
  @media only screen and (max-width: 1160px) {
    .article-header {
      padding: 0 20px; } }
  @media only screen and (max-width: 500px) {
    .article-header {
      margin: 60px auto; } }
  .article-header__title {
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 0.63px;
    color: #2A292E;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 20px; }
  .article-header__preamble {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.41px;
    color: #2A292E;
    margin-bottom: 20px; }
  .article-header__author {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.25px;
    color: #575757;
    font-weight: 700;
    font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    display: block;
    margin-bottom: 40px; }
  .article-header__image {
    margin-bottom: 40px; }
    .article-header__image img {
      max-width: 100%; }

.Map-block__heading {
  max-width: 68.75rem;
  width: 100%;
  margin: auto;
  padding: 2rem 0;
  /* 120/16 */
  font-size: 3.5rem;
  line-height: 1.3;
  color: #000;
  font-weight: 700;
  font-family: "Lab Grotesque Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  @media only screen and (max-width: 800px) {
    .Map-block__heading {
      font-size: 2.25rem;
      line-height: 42px; } }
  @media only screen and (max-width: 68.75rem) {
    .Map-block__heading {
      margin: 1rem 0;
      padding: 0 1.25rem; } }

.Map-block__map {
  min-height: 360px;
  display: block;
  width: 100%;
  background-color: #2385FC;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' x='0px' y='0px' viewBox='0 0 1916.3 1114.5' enable-background='new 0 0 1916.3 1114.5'%3E\a%3Cpath fill='%232D78FF' d='M0.4-3.1v1117.7L844.4-2.2C844.4-2.2,0.5-3.3,0.4-3.1z'/%3E\a%3Cpolygon fill='%232A83FF' points='844.4,-2.2 0.4,1114.6 1181.6,1114.6 '/%3E\a%3Cpolygon fill='%232D94FF' points='844.4,-2.2 1916.2,-2.2 1081.9,784.3 '/%3E\a%3Cpolygon fill='%2326A3FF' points='1181.6,1114.6 1081.8,784.4 1916.2,-2.2 1916.2,1114.7 '/%3E\a%3C/svg%3E");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }

.Image-block {
  max-width: 68.75rem;
  width: 100%;
  margin: auto;
  padding: 2rem 0;
  /* 120/16 */ }
  @media only screen and (max-width: 68.75rem) {
    .Image-block {
      margin: 1rem 0;
      padding: 0 1.25rem; } }
  .Image-block__image {
    max-width: 100%; }
    .Image-block__image-element {
      max-width: 100%; }

.Video-block {
  max-width: 68.75rem;
  width: 100%;
  margin: auto;
  padding: 2rem 0;
  /* 120/16 */ }
  @media only screen and (max-width: 68.75rem) {
    .Video-block {
      margin: 1rem 0;
      padding: 0 1.25rem; } }
  .Video-block__holder {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 100%;
    max-width: 100%;
    width: 100%;
    background-color: #2385FC;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' x='0px' y='0px' viewBox='0 0 1916.3 1114.5' enable-background='new 0 0 1916.3 1114.5'%3E\a%3Cpath fill='%232D78FF' d='M0.4-3.1v1117.7L844.4-2.2C844.4-2.2,0.5-3.3,0.4-3.1z'/%3E\a%3Cpolygon fill='%232A83FF' points='844.4,-2.2 0.4,1114.6 1181.6,1114.6 '/%3E\a%3Cpolygon fill='%232D94FF' points='844.4,-2.2 1916.2,-2.2 1081.9,784.3 '/%3E\a%3Cpolygon fill='%2326A3FF' points='1181.6,1114.6 1081.8,784.4 1916.2,-2.2 1916.2,1114.7 '/%3E\a%3C/svg%3E");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; }
  .Video-block__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .3s ease;
    z-index: 1; }

.whitepaper-form {
  width: 100%;
  max-width: 915px;
  margin: 60px auto;
  padding: 20px;
  background-size: cover;
  background-image: url("../img/placeholder-pattern.jpg"); }
  .whitepaper-form__holder {
    width: 100%;
    height: 100%;
    padding: 75px 8%;
    background: #fff;
    text-align: center; }
    @media only screen and (max-width: 500px) {
      .whitepaper-form__holder {
        padding: 35px 20px 90px 20px; } }
    .whitepaper-form__holder h4 {
      color: #2385FC;
      font-size: 28px;
      line-height: 38px; }
      @media only screen and (max-width: 500px) {
        .whitepaper-form__holder h4 {
          font-size: 20px;
          line-height: 30px; } }
    .whitepaper-form__holder input[type="email"] {
      width: 100%;
      max-width: 450px;
      margin: 40px auto;
      padding: 20px;
      border: 1px solid #2385FC;
      background: #fff; }
    .whitepaper-form__holder input[type="submit"] {
      background-color: #2385FC;
      padding: 13px 33px;
      display: inline-block;
      border-radius: 70px;
      text-decoration: none;
      color: #fff;
      font-family: "Lab Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 20px;
      border: none;
      transition: all .2s ease-in-out; }
      .whitepaper-form__holder input[type="submit"]:hover {
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2); }

.instagram-feed {
  width: 100%;
  max-width: 1100px;
  margin: 50px auto; }
  @media only screen and (max-width: 1160px) {
    .instagram-feed {
      padding: 0 20px; } }
  .instagram-feed__profile-link {
    font-size: 36px;
    line-height: 42px;
    color: #2385FC;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 20px;
    display: block; }
    @media only screen and (max-width: 500px) {
      .instagram-feed__profile-link {
        font-size: 28px;
        line-height: 32px; } }
  .instagram-feed__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .instagram-feed__holder {
    width: 25%;
    padding: 5px;
    display: block; }
    @media only screen and (max-width: 500px) {
      .instagram-feed__holder {
        width: 100%; } }
    .instagram-feed__holder img {
      max-width: 100%;
      display: block; }

.sign-up-form {
  width: 100%;
  max-width: 920px;
  margin: 100px auto; }
  @media only screen and (min-width: 930px) {
    .sign-up-form {
      padding-left: 1.5rem; } }
  @media only screen and (max-width: 930px) {
    .sign-up-form {
      padding: 0 1.25rem; } }
  .sign-up-form__title {
    font-size: 2rem;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 1.5rem; }
    @media only screen and (max-width: 620px) {
      .sign-up-form__title {
        font-size: 1.5rem; } }
    @media only screen and (max-width: 370px) {
      .sign-up-form__title {
        font-size: 1.25rem; } }
  .sign-up-form__text {
    font-size: 1.125rem;
    line-height: 1.3;
    color: #575757; }
  .sign-up-form__form-holder {
    display: inline-block;
    margin: 50px auto;
    width: 100%; }
    .sign-up-form__form-holder .wpcf7-form-control-wrap {
      display: block; }
  .sign-up-form__column {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px; }
    @media only screen and (max-width: 620px) {
      .sign-up-form__column {
        flex-direction: column; } }
    .sign-up-form__column span {
      width: 50%; }
      @media only screen and (max-width: 620px) {
        .sign-up-form__column span {
          width: 100%; } }
      .sign-up-form__column span:first-of-type {
        margin-right: 30px; }
        @media only screen and (max-width: 620px) {
          .sign-up-form__column span:first-of-type {
            margin-right: 0; } }
  .sign-up-form input {
    width: 100%;
    border: none;
    padding: 10px 0;
    outline: none;
    border-bottom: 1px solid #2385FC; }
  .sign-up-form input[type="submit"] {
    width: 50%;
    padding: 13px 33px;
    display: inline-block;
    border-radius: 70px;
    text-decoration: none;
    font-family: "Lab Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 20px;
    transition: all .2s ease-in-out;
    background-color: #2385FC;
    color: #fff;
    border: 0;
    height: auto;
    float: right;
    margin-top: 20px; }
    @media only screen and (max-width: 620px) {
      .sign-up-form input[type="submit"] {
        width: 100%;
        margin-top: 15px; } }
    .sign-up-form input[type="submit"]:hover {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2); }

.event-schedule {
  width: 100%;
  max-width: 920px;
  margin: 100px auto;
  padding: 1.5rem;
  background-color: #2385FC;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  @media only screen and (max-width: 750px) {
    .event-schedule {
      padding: 2rem 1.25rem; } }
  .event-schedule__wrap {
    padding: 0 1rem; }
  .event-schedule__title {
    font-size: 2rem;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #fff; }
    @media only screen and (max-width: 620px) {
      .event-schedule__title {
        font-size: 1.5rem; } }
    @media only screen and (max-width: 370px) {
      .event-schedule__title {
        font-size: 1.25rem; } }
  .event-schedule__row {
    color: #fff;
    padding-bottom: 1rem; }
    .event-schedule__row-title {
      font-size: 1.25rem; }
    .event-schedule__row-bulletlist {
      padding-top: 1rem;
      padding-left: 1.5rem; }
      .event-schedule__row-bulletlist-item {
        margin-bottom: 0.75rem;
        max-width: 520px; }

.event-persons {
  width: 100%;
  max-width: 920px;
  padding: 0;
  margin: 100px auto; }
  @media only screen and (max-width: 930px) {
    .event-persons {
      padding: 0 1.25rem; } }
  .event-persons__title {
    font-size: 2rem;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #1E1E1E; }
    @media only screen and (min-width: 930px) {
      .event-persons__title {
        padding-left: 1.5rem; } }
    @media only screen and (max-width: 620px) {
      .event-persons__title {
        font-size: 1.5rem; } }
    @media only screen and (max-width: 370px) {
      .event-persons__title {
        font-size: 1.25rem; } }
  .event-persons__person {
    width: 100%;
    padding: 1.5rem;
    margin: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    margin-bottom: 2rem; }
  .event-persons__person-holder {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap; }
  .event-persons__person-image {
    width: 100%;
    margin-bottom: 1.5rem; }
    @media only screen and (min-width: 640px) {
      .event-persons__person-image {
        flex: 1 1 auto;
        width: 45%; } }
    .event-persons__person-image-wrap {
      display: block; }
      @media only screen and (min-width: 640px) {
        .event-persons__person-image-wrap {
          padding-right: 2rem; } }
      .event-persons__person-image-wrap img {
        width: 100%;
        max-width: 100%; }
  .event-persons__person-content {
    width: 100%; }
    @media only screen and (min-width: 640px) {
      .event-persons__person-content {
        flex: 1 1 auto;
        width: 55%; } }
    .event-persons__person-content-name {
      font-size: 1.5rem;
      color: #2385FC;
      margin-bottom: 0.5rem; }
    .event-persons__person-content-company {
      font-size: 0.875rem;
      color: #1E1E1E;
      margin-bottom: 0.25rem;
      font-weight: 700; }
    .event-persons__person-content-title {
      font-size: 0.875rem;
      color: #1E1E1E;
      margin-bottom: 0.5rem;
      font-weight: 700; }
    .event-persons__person-content-description {
      font-size: 1rem;
      line-height: 1.6;
      color: #1E1E1E;
      margin-bottom: 1rem;
      padding-right: 1rem; }

.event-text {
  width: 100%;
  max-width: 920px;
  padding: 0;
  margin: 100px auto; }
  @media only screen and (max-width: 930px) {
    .event-text {
      padding: 0 1.25rem; } }
  .event-text__title {
    font-size: 3.5rem;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #1E1E1E; }
    @media only screen and (min-width: 930px) {
      .event-text__title {
        padding-left: 1.5rem; } }
    @media only screen and (max-width: 620px) {
      .event-text__title {
        font-size: 2rem; } }
    @media only screen and (max-width: 370px) {
      .event-text__title {
        font-size: 1.5rem; } }
  .event-text__content {
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.3;
    color: #575757;
    max-width: 720px; }
    @media only screen and (min-width: 930px) {
      .event-text__content {
        padding-left: 1.5rem; } }
    .event-text__content p, .event-text__content span, .event-text__content a {
      font-size: inherit;
      line-height: inherit;
      color: inherit; }
    .event-text__content p {
      margin-bottom: 1.5rem; }
    .event-text__content a {
      text-decoration: underline; }

html {
  scroll-behavior: smooth; }

body {
  padding-top: 65px;
  font-family: "Lab Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.text-align--left {
  text-align: left; }

.text-align--right {
  text-align: right; }

.text-align--center {
  text-align: center; }
