.ContactSection {
    //
    background-color: $darkBlue;
    color: $white;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    @include above($m) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    &-wrap {
        max-width: $content-max-width;
        margin-left: auto;
        margin-right: auto;
    }
    &-heading {
        font-size: $page-heading-size;
        line-height: 1.1;
        color: $white;
        font-weight: $bold;
        font-family: $heading;
        padding-bottom: 2rem;
        @include below($m) {
            font-size: $page-heading-size--small;
            line-height: 1.1;
        }
    }

    &-text {
        color: $white;
        font-size: 1.125rem;
        line-height: 1.44;
        padding-bottom: 2rem;
    }
    &-form {
        .wpcf7-text::placeholder {
            color: rgba(255, 255, 255, 0.2);
        }

        .wpcf7-textarea {
            width: 100%;
            margin-bottom: 2rem;
            background-color: rgba(0,0,0,0.1);
            border: 0;
            padding: 1rem;
            color: $white;

        }
        .wpcf7-form-control-wrap label {
            font-size: 1rem;
        }
        .wpcf7-form-control.wpcf7-submit {
            display: inline-block;
            width: auto;
        }
        .contact-form-plugin__columns {
           .ajax-loader {
               display: none;
               &.is-active {
                   display: block;
               }
           }
        }
    }
}